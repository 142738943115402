import Button from '@material-ui/core/Button';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { TAKE_CONTROL } from '../../constants/api';
import customProtocolCheck from '../Connect/custom-protocol-check';
import getOs from '../../utils/userOs';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import ErrorIcon from '@material-ui/icons/Error';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import axios from 'axios';

const downloadLinks = {
  win: 'https://www.system-monitor.com/dashboard/rap.php?Action=downloadtcviewermspanywherewin',
  mac: 'https://www.system-monitor.com/dashboard/rap.php?Action=downloadtcviewermspanywhereosx',
  android: 'https://play.google.com/store/apps/details?id=sw.androidviewer&hl=en_US',
  ios: 'https://apps.apple.com/app/n-able-rmm/id1104242696',
};

const useStyles = makeStyles(theme => ({
  modal: {
    padding: 20,
  },
  icon: {
    verticalAlign: 'middle',
  },
  buttonContainer: {
    justifyContent: 'space-between',
    paddingLeft: '30px',
    paddingRight: '30px',
  },
}));

const ConnectButton = props => {
  const { t } = useTranslation();
  const authUser = useSelector(state => state.user);
  const [connDialog, setConnDialog] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState(downloadLinks[getOs()]);
  const [tControlUrl, setTControlUrl] = useState(null);
  const [dialogText, setDialogText] = useState(t('take control not installed'));
  const [downloadText, setDownloadText] = useState(t('download'));
  const [connecting, setConnecting] = useState(false);
  const [errorText, setErrorText] = useState('');
  const classes = useStyles();

  const handleClose = () => {
    if (!connecting) {
      setConnDialog(false);
    }
  };

  useEffect(() => {
    if (tControlUrl) {
      customProtocolCheck(
        tControlUrl,
        url => {
          url && setDownloadUrl(url);
          url && setDialogText(t('take control not installed ios'));
          url && setDownloadText(t('install'));
          setConnecting(false);
        },
        () => {
          setConnDialog(false);
          setTimeout(() => setConnecting(false), 1000);
        },
        10000,
        () => {},
        downloadUrl,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tControlUrl]);

  const onConnect = async authUser => {
    setConnecting(true);
    setConnDialog(true);

    try {
      const takeControlUrl = TAKE_CONTROL;
      const searchParams = new URLSearchParams();
      searchParams.append('userID', authUser.uid);
      searchParams.append('accessToken', authUser.accessToken);
      searchParams.append('deviceid', props.server.serverid);
      searchParams.append('region', props.server.region);
      searchParams.append('type', props.type);

      const { data } = await axios.get(takeControlUrl + searchParams);

      if (data.takeControlURL) {
        setTControlUrl(data.takeControlURL);
      } else {
        setErrorText('Invalid response. Please try again');
        setConnecting(false);
      }
    } catch (err) {
      let errorText = 'connection error';
      if (err.response) {
        if (err.response.status === 401) {
          errorText = 'You are not authorized to control this device';
        }
      } else {
        errorText = err.message;
      }
      setErrorText(errorText);
      setConnecting(false);
    }
  };

  return (
    <div>
      <Dialog onClose={handleClose} className={classes.modal} open={connDialog}>
        {connecting ? (
          <DialogContent className={classes.modal}>
            <CircularProgress />
          </DialogContent>
        ) : !errorText ? (
          <div>
            <DialogContent className={classes.modal}>
              <div>
                <Typography>{dialogText}</Typography>
              </div>
            </DialogContent>
            <DialogActions classes={{ root: classes.buttonContainer }}>
              <Button href={downloadUrl} color="primary" variant="contained">
                {downloadText}
              </Button>
              <Button onClick={handleClose} variant="contained">
                {t('close')}
              </Button>
            </DialogActions>
          </div>
        ) : (
          <DialogContent className={classes.modal}>
            <div>
              <Typography variant="subtitle2" color="error">
                <ErrorIcon className={classes.icon} color="error" /> {errorText}
              </Typography>
            </div>
          </DialogContent>
        )}
      </Dialog>
      <Button
        onClick={() => onConnect(authUser)}
        disabled={
          _.toInteger(props.server.online) === 0 || (_.toInteger(props.server.online) === 1 && props.server.overdue)
        }
        variant="contained"
        color="primary"
      >
        {'Connect'}
      </Button>
    </div>
  );
};
export default ConnectButton;
