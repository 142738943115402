import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withFirebase } from '../Firebase';
import TableIcons from '../../constants/tableIcons';
import { useTranslation } from 'react-i18next';
import MaterialTable from '../MaterialTable/MaterialTable';
import Button from '@material-ui/core/Button';
import AdornedButton from '../Button/AdornedButton';
import SaveMessageDialog from './dialogs/SaveMessageDialog';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { format } from 'date-fns';
import Regions from '../../constants/regions.json';
import { useSelector } from 'react-redux';
import { getDevicesFromDatabaseForSelect, getSitesFromDatabase } from '../../utils/common';

const useStyles = makeStyles(theme => ({
  table: {
    margin: theme.spacing(2, 0),
  },
}));

const MessagingTable = ({ data, columns }) => {
  const { t } = useTranslation();

  return <MaterialTable icons={TableIcons} title={t('messaging')} columns={columns} data={data} />;
};

const MessagingPage = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const authUser = useSelector(state => state.user);
  const [loading, setLoading] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [showRemoveMessage, setShowRemoveMessage] = useState(false);
  const [showSaveMessage, setShowSaveMessage] = useState(false);
  const [sites, setSites] = useState([]);
  const [clients, setClients] = useState([]);
  const [devices, setDevices] = useState([]);

  const closeRemoveMessage = () => {
    setSelectedMessage(null);
    setShowRemoveMessage(false);
  };

  const deleteMessage = message => {
    setSelectedMessage(message);
    setShowRemoveMessage(true);
  };

  const updateMessage = message => {
    setSelectedMessage(message);
    setShowSaveMessage(true);
  };

  const showAddDialog = () => {
    setSelectedMessage(null);
    setShowSaveMessage(true);
  };

  const removeMessage = () => {
    setDeleteLoading(true);
    setShowRemoveMessage(false);
    props.firebase
      .message(selectedMessage.uid)
      .delete()
      .then(() => {
        setDeleteLoading(false);
        setSelectedMessage(null);
      })
      .catch(() => {
        setDeleteLoading(false);
      });
  };

  const getFormatDate = timestamp => {
    return format(timestamp, 'MMM do, yyyy hh:mm a');
  };

  const getSites = async () => {
    const sites = (await getSitesFromDatabase(props.firebase, authUser)).map(site => {
      return {
        id: site.siteid,
        title: `${site.clientName} - ${site.name}`,
      };
    });
    setSites(sites);
  };

  const getClients = async () => {
    const clientSnapshot = await props.firebase.clients().get();
    const newClients = clientSnapshot.docs.map(doc => {
      const docData = doc.data();
      return {
        id: docData.clientid,
        title: docData.name,
      };
    });
    setClients(newClients);
  };

  const getDevices = async () => {
    const devices = (await getDevicesFromDatabaseForSelect(props.firebase)).data.map(device => {
      return {
        id: device.id?.split('.')[1] ?? '',
        title: device.name ?? '',
      };
    });
    setDevices(devices);
  };

  const fetchAutoCompleteList = async () => {
    await Promise.all([getClients(), getSites(), getDevices()]);
  };

  const fetchMessages = async () => {
    const messageSnapshot = await props.firebase.messages().get();
    const newMessages = _.map(messageSnapshot.docs, doc => {
      const messageDoc = doc.data();
      messageDoc['uid'] = doc.id;
      messageDoc['id'] = doc.id;
      return messageDoc;
    });
    setMessages(newMessages);
  };

  const fetchData = async () => {
    try {
      await fetchAutoCompleteList();
      await fetchMessages();
    } catch (error) {
      setError(String(error));
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: t('dashboard'),
      field: 'dashboard',
      render: rowData => {
        return rowData.dashboard
          ?.split(',')
          .map(regionCode => Regions.find(region => region.code === regionCode)?.name)
          .join(', ');
      },
    },
    {
      title: t('client'),
      field: 'client',
      render: rowData => {
        return rowData.client
          ?.split(',')
          .map(clientId => clients.find(client => client.id === clientId)?.title)
          .join(', ');
      },
    },
    {
      title: t('site'),
      field: 'site',
      render: rowData => {
        return rowData.site
          ?.split(',')
          .map(siteId => sites.find(site => site.id === siteId)?.title)
          .join(', ');
      },
    },
    {
      title: t('title'),
      field: 'title',
      cellStyle: {
        verticalAlign: 'top',
        fontWeight: 'bold',
      },
    },
    {
      title: t('schedule'),
      field: 'schedule',
      render: rowData => <div>{getFormatDate(rowData.schedule)}</div>,
    },
    {
      title: '',
      field: 'actions',
      render: rowData => (
        <div>
          <IconButton onClick={() => updateMessage(rowData)} size="small" disabled={loading}>
            <EditIcon color="primary" />
          </IconButton>
          <IconButton onClick={() => deleteMessage(rowData)} size="small" disabled={loading}>
            <HighlightOffIcon color="error" />
          </IconButton>
        </div>
      ),
    },
  ];

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSaveMessage]);

  return (
    <Container maxWidth="md">
      {!loading ? (
        <div>
          <Button variant="contained" color="primary" onClick={() => showAddDialog()}>
            {t('new message')}
          </Button>
          <SaveMessageDialog
            firebase={props.firebase}
            reload={fetchMessages}
            sites={sites}
            clients={clients}
            devices={devices}
            show={showSaveMessage}
            toggler={setShowSaveMessage}
          />
          <SaveMessageDialog
            firebase={props.firebase}
            reload={fetchMessages}
            sites={sites}
            clients={clients}
            devices={devices}
            show={showSaveMessage}
            toggler={setShowSaveMessage}
            currentMessage={selectedMessage}
          />
          <div className={classes.table}>
            <MessagingTable columns={columns} data={messages} />
          </div>
          {selectedMessage ? (
            <Dialog open={showRemoveMessage} onClose={() => closeRemoveMessage()}>
              <DialogContent>{t('are you sure to remove this message')}</DialogContent>
              <DialogActions>
                <AdornedButton
                  variant="contained"
                  color="secondary"
                  onClick={() => closeRemoveMessage()}
                  disabled={loading || deleteLoading}
                >
                  {t('no')}
                </AdornedButton>
                <AdornedButton
                  variant="contained"
                  color="primary"
                  onClick={() => removeMessage()}
                  disabled={loading || deleteLoading}
                  loading={deleteLoading}
                >
                  {t('yes')}
                </AdornedButton>
              </DialogActions>
            </Dialog>
          ) : null}
          {error ? (
            <Typography variant="subtitle2" color="error">
              {error}
            </Typography>
          ) : null}
        </div>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress />
        </div>
      )}
    </Container>
  );
};
export default withFirebase(MessagingPage);
