import Swal from 'sweetalert2';
import i18n from '../i18n';
import { APP_GREEN_COLOR } from '../constants/const';
import axios from 'axios';
import * as API from '../constants/api';

export const APP_PERMISSIONS = ['devices', 'messaging', 'test'];
export const APP_RESOURCES = ['au', 'eu', 'us'];

export const REGION_LOOKUP = {
  au: 'AU',
  eu: 'EU',
  us: 'US',
};

export function getLast5Backups(backup_history) {
  let backups = [];
  try {
    if (backup_history) {
      backups = JSON.parse(backup_history);
    }
  } catch {
    // Error occurred while parsing
  }
  return backups.slice(Math.max(backups.length - 5, 1));
}

export async function downloadFileFromUrl(url, filename) {
  const link = document.createElement('a');
  link.href = url;
  if (filename) {
    link.setAttribute('download', filename);
  } else {
    link.setAttribute('download', '');
  }
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export function showErrorAlert(message) {
  Swal.fire({
    icon: 'error',
    title: i18n.t('oops'),
    confirmButtonText: i18n.t('ok'),
    confirmButtonColor: APP_GREEN_COLOR,
    text: message,
  });
}

export function showSuccessAlert(message) {
  Swal.fire({
    icon: 'success',
    title: i18n.t('success'),
    confirmButtonText: i18n.t('ok'),
    confirmButtonColor: APP_GREEN_COLOR,
    text: message,
  });
}

export async function getUserData(firebase, userId) {
  const idToken = await firebase.auth.currentUser.getIdToken();
  const userDoc = await firebase.user(userId).get();
  const docData = userDoc.data();
  const authUser = {
    uid: userId,
    token: idToken,
  };
  let isTwoFactorAuthorized;
  const twoFactorDocs = await firebase.twoFactor(idToken);
  if (twoFactorDocs?.length) {
    isTwoFactorAuthorized = Boolean(twoFactorDocs[0].data().verified);
  }
  await i18n.changeLanguage(docData.interfaceLanguage || docData.language || 'en');
  authUser.name = docData.name;
  authUser.accessToken = docData.accessToken;
  authUser.region = docData.region;
  authUser.language = docData.language;
  authUser.interfaceLanguage = docData.interfaceLanguage;
  authUser.cirrusProOnly = docData.cirrusProOnly;
  authUser.email = docData.email;
  authUser.role = docData.role;
  authUser.permissions = docData.permissions;
  authUser.resources = docData.resources;
  authUser.unsubscribedNotifications = docData.unsubscribedNotifications;
  authUser.isTwoFactorAuthorized = isTwoFactorAuthorized;
  authUser.phoneNumber = docData.phoneNumber ?? '';

  if ('organization' in docData) {
    try {
      const orgDoc = await docData.organization.get();
      authUser.organization = orgDoc.data()?.name;
    } catch (e) {
      console.log(e);
    }
  }
  return authUser;
}

// Users with only connect timeout should be 8 hours (480 minutes)
// Uses (with admin permissions) timeout should be 60 minutes
export function timeOutInMilliSeconds(isAdmin) {
  return (isAdmin ? 60 : 480) * 60 * 1000;
}

export function isAuthUserAdmin(authUser) {
  return authUser?.role === 'admin' || authUser?.role === 'system_admin' || authUser?.role === 'super_admin';
}

export async function getSitesFromDatabase(firebase, authUser) {
  const allowedRegions = getAllowedRegions(authUser);
  if (allowedRegions.length < 1) {
    return [];
  }
  const siteSnapshot = await firebase.sites().where('region', 'in', allowedRegions).get();
  return siteSnapshot.docs.map(doc => {
    return {
      uid: doc.id,
      id: doc.id,
      ...doc.data(),
    };
  });
}

export async function getDevicesFromDatabase(firebase, authUser) {
  const allowedRegions = getAllowedRegions(authUser);
  if (allowedRegions.length < 1) {
    return [];
  }
  const deviceSnapshot = await firebase.devices().where('region', 'in', allowedRegions).get();
  let devices = deviceSnapshot.docs.map(doc => {
    return {
      uid: doc.id,
      id: doc.id,
      ...doc.data(),
    };
  });
  if (authUser.cirrusProOnly) {
    devices = devices.filter(device => device.software?.includes('CirrusPro'));
  }
  return devices;
}

export async function getDevicesFromDatabaseForSelect() {
  return await axios.get(`${API.GET_DEVICES_FOR_SELECT}`);
}

export async function sleep(timeInSecond) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, timeInSecond * 1000);
  });
}

export function getRoleListFromRole(role) {
  let roles = ['user'];
  if (role === 'system_admin') {
    roles = ['system_admin', 'super_admin', 'admin', 'user'];
  }
  if (role === 'super_admin') {
    roles = ['admin', 'user'];
  }
  return roles;
}

export function checkIfPermissionIsGranted(permission, authUser) {
  if (authUser.role === 'system_admin' || authUser.role === 'super_admin') {
    return true;
  }
  if (permission === 'admin') {
    return authUser.role === 'admin';
  }
  return authUser.role === 'admin' && (authUser.permissions ?? '').includes(permission);
}

export function getAllowedRegions(authUser) {
  if (authUser.role === 'system_admin' || authUser.role === 'super_admin') {
    return APP_RESOURCES;
  }
  return (authUser.resources ?? '').split(',');
}

export const replaceError = value => {
  if (typeof value === 'number') {
    return value;
  }
  if (typeof value !== 'string') {
    return '';
  }
  if (value.length < 2) {
    return '';
  }
  const exceptions = [
    'Thread failed to start.',
    'Script not found',
    'Script timed out',
    'Script awaiting download',
    'Script output not found',
    'Script failed to start.',
  ];
  if (exceptions.some(exception => exception.includes(value))) {
    return '';
  }
  if (/[e|E]xception/.test(value ?? '') || value?.includes('GetResponse') || value?.length > 30 || value === ',') {
    return '';
  }
  return value;
};
