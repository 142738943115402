import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { showToast } from '../../store/actions/toastAction';
import AdornedButton from '../Button/AdornedButton';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const PasswordChangePage = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          {t('change password')}
        </Typography>
        <PasswordChangeForm />
      </div>
    </Container>
  );
};

const INITIAL_STATE = {
  passwordOne: '',
  passwordTwo: '',
};

const PasswordChangeFormBase = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const params = new URLSearchParams(props.location.search);
  const code = params.get('oobCode');
  const mode = params.get('mode');
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({ ...INITIAL_STATE });

  const onSubmit = event => {
    if (mode && code) {
      if (mode === 'resetPassword') {
        const { passwordOne } = formData;

        setLoading(true);
        props.firebase
          .doPasswordCodeVerification(code)
          .then(() => {
            props.firebase
              .doPasswordReset(code, passwordOne)
              .then(() => {
                setFormData({ ...INITIAL_STATE });
                dispatch(showToast(t('password_change_success')));
                setLoading(false);
                props.firebase.doSignOut().then(() => {
                  props.history.push(ROUTES.SIGN_IN);
                });
              })
              .catch(error => {
                setLoading(false);
                dispatch(showToast(String(error), 'error'));
              });
          })
          .catch(error => {
            setLoading(false);
            dispatch(showToast(String(error), 'error'));
          });
      } else {
        dispatch(showToast('Wrong mode specified.', 'error'));
      }
    } else {
      dispatch(showToast('The mode and code are mandatory.', 'error'));
    }

    event.preventDefault();
  };

  const onChange = event => {
    event.persist();
    setFormData(prevFormData => ({
      ...prevFormData,
      [event.target.name]: event.target.value,
    }));
  };

  const { passwordOne, passwordTwo } = formData;
  const isInvalid = passwordOne !== passwordTwo || passwordOne === '';

  return (
    <form className={classes.form} onSubmit={onSubmit}>
      <TextField
        name="passwordOne"
        margin="normal"
        required
        fullWidth
        value={passwordOne || ''}
        onChange={onChange}
        type="password"
        label={t('new password')}
        variant="outlined"
      />
      <TextField
        name="passwordTwo"
        margin="normal"
        required
        fullWidth
        value={passwordTwo || ''}
        onChange={onChange}
        type="password"
        label={t('confirm new password')}
        variant="outlined"
      />
      <AdornedButton
        disabled={isInvalid || loading}
        loading={loading}
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
      >
        {t('reset password')}
      </AdornedButton>
    </form>
  );
};

export default withRouter(PasswordChangePage);

const PasswordChangeForm = compose(withRouter, withFirebase)(PasswordChangeFormBase);

export { PasswordChangeForm };
