import React, {useState} from 'react';
import { Menu, MenuItem, styled} from "@material-ui/core";
import {MenuProps} from "@material-ui/core";
import _ from 'lodash';
import DownIcon from "@material-ui/icons/ArrowDropDown";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import {Link} from "react-router-dom";

const useStyles = makeStyles(() => ({
    topContainer: {
        display: 'flex',
        alignItems: 'center'
    }
}));

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(4),
        minWidth: 180,
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
    },
}));

export default function NavDropDown(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className={classes.topContainer}>
            <Button
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="text"
                disableElevation
                color="inherit"
                onMouseOver={handleClick}
                endIcon={<DownIcon />}
            >
                { props.label }
            </Button>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                    onMouseLeave: handleClose
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                { _.map(props.items, item => (
                    <MenuItem
                        onClick={handleClose}
                        key={`${item.label}${item.link}`}
                        disableRipple
                        component={Link}
                        to={item.link}
                    >
                        { item.label }
                    </MenuItem>
                ))}
            </StyledMenu>
        </div>
    );
}