import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
// import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withFirebase } from '../Firebase';
import TableIcons from '../../constants/tableIcons';
import { useTranslation } from 'react-i18next';
import MaterialTable from '../MaterialTable/MaterialTable';
import { CsvBuilder } from 'filefy';
import LedIndicator from '../Common/LedIndicator';
import 'jspdf-autotable';
import { differenceInMinutes } from 'date-fns';
import { getDevicesFromDatabase, getLast5Backups, REGION_LOOKUP, replaceError } from '../../utils/common';
import ConnectButton from '../Button/ConnectButton';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from '../../store/actions/toastAction';
import {
  DEVICE_EXPORT_COLUMNS,
  getDeviceExportRowData,
  getLastResponse,
  getSoftwareAssetExportRowData,
  SOFTWARE_ASSET_EXPORT_COLUMNS,
} from './const';
// import ConnectTunnelButton from "../Button/ConnectTunnelButton";
import axios from 'axios';
import { GET_ACTIVE_TUNNELS } from '../../constants/api';
import Link from '@material-ui/core/Link';
import DeviceInformationDialog from './dialogs/DeviceInformationDialog';

// const jsPDF = typeof window !== "undefined" ? require("jspdf").jsPDF : null;

const useStyles = makeStyles(theme => ({
  table: {
    margin: theme.spacing(2, 0),
  },
  link: {
    cursor: 'pointer',
    fontWeight: 'bold',
  },
  connectContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
}));

const DeviceTable = ({ data, columns }) => {
  const { t } = useTranslation();

  return (
    <MaterialTable
      icons={TableIcons}
      title={t('devices')}
      columns={columns}
      data={data}
      options={{
        rowStyle: {
          fontSize: 14,
        },
        filtering: true,
        exportMenu: [
          {
            label: t('device_export_csv'),
            exportFunc: (columns, data, tableData) => {
              const fileName = 'Devices';
              const builder = new CsvBuilder(fileName + '.csv');
              builder
                .setDelimeter(',')
                .setColumns(DEVICE_EXPORT_COLUMNS)
                .addRows(tableData.filteredData.map(row => getDeviceExportRowData(row)))
                .exportFile();
            },
          },
          {
            label: t('software_asset_export_csv'),
            exportFunc: (columns, data, tableData) => {
              const fileName = 'Software_Assets';
              const builder = new CsvBuilder(fileName + '.csv');
              builder
                .setDelimeter(',')
                .setColumns(SOFTWARE_ASSET_EXPORT_COLUMNS)
                .addRows(tableData.filteredData.flatMap(row => getSoftwareAssetExportRowData(row)))
                .exportFile();
            },
          },
          // , {
          //     label: t('export_pdf'),
          //     exportFunc: (cols, data, tableData) => {
          //         if (jsPDF !== null) {
          //             let content = {
          //                 startY: 50,
          //                 head: [exportColumns],
          //                 body: tableData.filteredData.map((row) => getExportRowData(row)),
          //                 headStyles: { fillColor: [0, 132, 61] },
          //                 columnStyles: {
          //                     0: {cellWidth: 50},
          //                     5: {cellWidth: 50},
          //                     7: {cellWidth: 60},
          //                     8: {cellWidth: 80},
          //                     9: {cellWidth: 50},
          //                 }
          //             };
          //
          //             const unit = "pt";
          //             const size = "A4";
          //             const orientation = "landscape";
          //
          //             const doc = new jsPDF(orientation, unit, size);
          //             doc.setFontSize(15);
          //             doc.text("Devices", 40, 40);
          //             doc.autoTable(content);
          //             doc.save("Devices.pdf");
          //         }
          //     }
          // }
        ],
      }}
    />
  );
};

const DevicePage = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const authUser = useSelector(state => state.user);
  const [loading, setLoading] = useState(true);
  const [isDeviceInfoModalOpen, setIsDeviceInfoModalOpen] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [devices, setDevices] = useState([]);
  const dispatch = useDispatch();

  const openDeviceInfoModal = deviceData => {
    setIsDeviceInfoModalOpen(true);
    setSelectedDevice(deviceData);
  };

  // Fix the material table filter reset issue when modal is present
  const [columns] = useState([
    {
      title: t('region'),
      field: 'region',
      width: '60px',
      lookup: REGION_LOOKUP,
      render: rowData => <div>{rowData.region?.toUpperCase()}</div>,
    },
    {
      title: t('client'),
      field: 'clientName',
    },
    {
      title: t('site'),
      field: 'siteName',
    },
    {
      title: t('name'),
      field: 'name',
      render: rowData => (
        <Link className={classes.link} onClick={() => openDeviceInfoModal(rowData)}>
          {rowData.name || ''}
        </Link>
      ),
    },
    {
      title: t('rain_bird_software'),
      field: 'software',
    },
    {
      title: t('last_response'),
      field: 'last_scan_time',
      render: rowData => <div>{getLastResponse(rowData.utc_apt, rowData.utc_offset)}</div>,
    },
    {
      title: t('serial_number'),
      field: 'device_serial',
    },
    {
      title: t('state'),
      field: 'state',
      width: '50px',
      render: rowData => (
        <div>
          <LedIndicator online={rowData.state === 'Online'} />
        </div>
      ),
      lookup: {
        Online: 'Online',
        Offline: 'Offline',
      },
    },
    {
      title: t('messaging_agent'),
      field: 'pulse',
      width: '50px',
      render: rowData => (
        <div>
          <LedIndicator online={rowData.pulse === 'Online'} />
        </div>
      ),
      lookup: {
        Online: 'Online',
        Offline: 'Offline',
      },
    },
    // {
    //     title: t('tunnel'),
    //     field: 'tunnel',
    //     lookup: {
    //         "ENABLED": "ENABLED",
    //         "DISABLED": "DISABLED"
    //     }
    // },
    // {
    //     title: t('ngrok_service'),
    //     field: 'ngrokStatus',
    //     lookup: {
    //         "Online": "Online",
    //         "Offline": "Offline"
    //     },
    //     render: (rowData) => (
    //         <div>
    //             <LedIndicator online={rowData.ngrokStatus === 'Online'}/>
    //         </div>
    //     ),
    // },
    {
      title: t('connect'),
      render: rowData => (
        <div className={classes.connectContainer}>
          <ConnectButton server={rowData} type="admin_control" />
          {/*<ConnectTunnelButton firebase={props.firebase} server={rowData}/>*/}
        </div>
      ),
    },
  ]);

  useEffect(() => {
    const curDate = new Date();
    async function fetchData() {
      try {
        const devices = await getDevicesFromDatabase(props.firebase, authUser);
        // const tunnels = (await axios.get(GET_ACTIVE_TUNNELS)).data;
        let newDevices = devices.map(device => {
          if (typeof device['device_serial'] === 'object') {
            device['device_serial'] = '';
          }
          const backups = getLast5Backups(device.backup_history);
          return {
            ...device,
            software: replaceError(device.software),
            country: replaceError(device.country),
            city: replaceError(device.city),
            download_speed: replaceError(device.download_speed),
            upload_speed: replaceError(device.upload_speed),
            state: _.toInteger(device.online) && !device.overdue ? 'Online' : 'Offline',
            pulse: differenceInMinutes(curDate, device.last_pulse_online ?? 0) < 10 ? 'Online' : 'Offline',
            last_backups: backups,
            tunnel: device.tunnelEnabled ? 'ENABLED' : 'DISABLED',
            // ngrokStatus: tunnels.some(tunnel => tunnel.labels?.rmmid === device.serverid) ? 'Online' : 'Offline',
            backup_status: backups?.every(backup => backup.status === 'PASS') ? 'PASS' : 'FAIL',
          };
        });
        newDevices = newDevices.filter(device => Boolean(device.region));
        setDevices(newDevices);
        setLoading(false);
      } catch (error) {
        dispatch(showToast(`${error}`, 'error'));
        setLoading(false);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container maxWidth="xl">
      {!loading ? (
        <div>
          <div className={classes.table}>
            <DeviceTable columns={columns} data={devices} />
          </div>
          {isDeviceInfoModalOpen ? (
            <DeviceInformationDialog
              device={selectedDevice}
              show={isDeviceInfoModalOpen}
              toggler={setIsDeviceInfoModalOpen}
              firebase={props.firebase}
            />
          ) : null}
        </div>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress />
        </div>
      )}
    </Container>
  );
};

export default withFirebase(DevicePage);
