import React, { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { withFirebase } from '../Firebase';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AdornedButton from '../Button/AdornedButton';
import * as API from '../../constants/api';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import _ from 'lodash';
import MenuItem from '@material-ui/core/MenuItem';
import { getDevicesFromDatabaseForSelect, showErrorAlert, showSuccessAlert } from '../../utils/common';
import { useSelector } from 'react-redux';
import axios from 'axios';

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '15px',
  },
  select: {
    marginTop: '15px',
  },
}));

const routeArr = [
  {
    value: 'site_setting',
    label: 'site_setting',
  },
  {
    value: 'email',
    label: 'email',
  },
  {
    value: 'sms',
    label: 'sms',
  },
  {
    value: 'email_sms',
    label: 'email_sms',
  },
];
const TestPage = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const authUser = useSelector(state => state.user);
  const [failureLoading, setFailureLoading] = useState(false);
  const [recoveryLoading, setRecoveryLoading] = useState(false);
  const [devices, setDevices] = useState([]);
  const [route, setRoute] = useState('site_setting');
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [check, setCheck] = useState('Overdue');
  const [checkList, setCheckList] = useState([]);

  const getData = async () => {
    setLoading(true);
    try {
      const configSnapshot = await props.firebase.configs().where('check_type', '==', 'alert').get();
      const checkList = configSnapshot.docs.map(doc => {
        let check = doc.data().description;
        if (check?.includes('Script Check - ')) {
          check = check.split('Script Check - ')[1];
        }
        return check;
      });
      setCheckList(['Overdue', ...checkList]);
      const devices = (await getDevicesFromDatabaseForSelect(props.firebase, authUser)).data.map(device => {
        return {
          ...device,
          title: `${device.region?.toUpperCase()} - ${device.clientName} - ${device.siteName} - ${device.name}`,
        };
      });
      setDevices(devices);
    } finally {
      setLoading(false);
    }
  };

  const isInvalid = !check || !selectedDevices?.length;

  const sendFailingCheck = async flag => {
    const params = new URLSearchParams({
      check,
      deviceIds: selectedDevices.map(device => device.id).join(','),
      isFixedCheck: flag,
      alertRouting: route,
      testType: check === 'Overdue' ? 'overdue' : 'failing_check',
    });
    if (flag) setRecoveryLoading(true);
    else setFailureLoading(true);

    try {
      await axios.get(`${API.SEND_FAILURE_CHECK}?` + params);
      showSuccessAlert(t('test_notification_set_success'));
    } catch (e) {
      showErrorAlert(String(e));
    } finally {
      if (flag) setRecoveryLoading(false);
      else setFailureLoading(false);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !loading ? (
    <Container maxWidth="sm">
      <Autocomplete
        multiple
        id="devices-standard"
        options={devices}
        getOptionLabel={option => option.title}
        getOptionSelected={(option, value) => option.id === value.id}
        onChange={(event, values) => setSelectedDevices(values)}
        value={selectedDevices}
        className={classes.select}
        renderInput={params => (
          <TextField {...params} variant="standard" label={t('devices')} placeholder={t('devices')} name="devices" />
        )}
      />
      <div className={classes.select}>
        <FormControl fullWidth={true}>
          <InputLabel id="routing-select-label">{t('alert_routing')}</InputLabel>
          <Select
            labelId="routing-select-label"
            id="routing-select"
            name="routing"
            onChange={event => setRoute(event.target.value)}
            value={route}
            fullWidth
            disabled={loading}
          >
            {_.map(routeArr, routeOption => (
              <MenuItem key={routeOption.value} value={routeOption.value}>
                {t(routeOption.label)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className={classes.select}>
        <FormControl fullWidth={true}>
          <InputLabel id="check-select-label">{t('check')}</InputLabel>
          <Select
            labelId="check-select-label"
            id="check-select"
            name="check"
            onChange={event => setCheck(event.target.value)}
            value={check}
            fullWidth
            disabled={loading}
          >
            {_.map(checkList, check => (
              <MenuItem key={check} value={check}>
                {check}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className={classes.buttonContainer}>
        <AdornedButton
          variant="contained"
          color="secondary"
          onClick={() => sendFailingCheck(false)}
          disabled={failureLoading || isInvalid}
          loading={failureLoading}
        >
          {t('test_failure')}
        </AdornedButton>
        <AdornedButton
          variant="contained"
          color="primary"
          onClick={() => sendFailingCheck(true)}
          disabled={recoveryLoading || isInvalid}
          loading={recoveryLoading}
        >
          {t('test_recovery')}
        </AdornedButton>
      </div>
    </Container>
  ) : (
    <div style={{ textAlign: 'center' }}>
      <CircularProgress />
    </div>
  );
};

export default withFirebase(TestPage);
