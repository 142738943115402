import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Card from '@material-ui/core/Card';
import { withFirebase } from '../Firebase';
import { useTranslation } from 'react-i18next';
import LedIndicator from '../Common/LedIndicator';
import ConnectButton from '../Button/ConnectButton';
import Last5Backup from '../Common/Last5Backup';
import { getLast5Backups, replaceError } from '../../utils/common';
import { showToast } from '../../store/actions/toastAction';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
  paper: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
  },
  serverName: {
    marginBottom: theme.spacing(2),
  },
  even: {
    backgroundColor: '#f5f5f5',
  },
  flexFull: {
    flex: 1,
  },
  flexContainer: {
    display: 'flex',
  },
  infoLeftContainer: {
    width: '150px',
  },
  buttonItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loading: {
    textAlign: 'center',
    padding: 30,
  },
  failingChecksBanner: {
    display: 'flex',
    alignItems: 'center',
  },
  failincChecksArrow: {
    transition: 'transform 0.2s',
    cursor: 'pointer',
  },
  failingChecksArrowOpen: {
    transform: 'rotate(180deg)',
  },
  failingChecksContainer: {
    padding: '5px',
    marginBottom: '5px',
    fontSize: '8px',
  },
  failingChecksText: {
    fontSize: '12px',
  },
}));

const ConnectPage = props => {
  const classes = useStyles();

  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [servers, setServers] = useState({});
  const [failingChecks, setFailingChecks] = useState({});
  const [expandedChecks, setExpandedChecks] = useState([]);
  const dispatch = useDispatch();

  const fetchData = async () => {
    try {
      const { firebase } = props;
      const { currentUser } = firebase.auth;

      // Retrieve sites that the current user is an administrator of
      const querySnapshot = await firebase.sites().where('administrators', 'array-contains', currentUser.uid).get();
      const sites = querySnapshot.docs.map(doc => doc.data());

      // Filter sites that have the 'connect' feature setting
      const filteredSites = _.filter(sites, site => _.includes(site.featureSetting, 'connect'));
      if (_.isEmpty(filteredSites)) {
        return;
      }

      // Extract the site IDs and site-region IDs from the filtered sites
      const siteIds = _.map(filteredSites, 'siteid');
      const siteRegIds = _.map(filteredSites, site => `${site.region}.${site.siteid}`);

      // Retrieve devices associated with the filtered sites
      const devicesSnapshot = await firebase.devices().where('siteid', 'in', siteIds).get();
      setServers(
        devicesSnapshot.docs.map(doc => {
          const server = doc.data();
          return {
            ...server,
            software: typeof server.software === 'string' ? server.software : '',
          };
        }),
      );

      // Retrieve failing checks associated with the filtered sites
      const failingChecksSnapshot = await firebase.failingChecks().where('siteid', 'in', siteRegIds).get();
      setFailingChecks(
        _.reduce(
          failingChecksSnapshot.docs,
          (checks, data) => {
            checks[data.serverid] = _.get(checks, data.serverid, []).concat(data);
            return checks;
          },
          {},
        ),
      );
    } catch (error) {
      // Handle errors and show an error toast
      dispatch(showToast(`${error}`, 'error'));
    } finally {
      // Set the loading state to false
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLocationString = server => {
    const city = replaceError(server.city);
    const country = replaceError(server.country);
    if (!city && !country) {
      return 'N/A';
    }
    return `${city}, ${country}`;
  };

  const toggleFailingCheck = serverId => () => {
    const isExpanded = expandedChecks.indexOf(serverId);
    if (isExpanded >= 0) {
      setExpandedChecks(_.filter(expandedChecks, server => serverId !== server));
    } else {
      setExpandedChecks([serverId, ...expandedChecks]);
    }
  };

  return (
    <Container maxWidth="md">
      {loading ? (
        <div className={classes.loading}>
          <CircularProgress align="center" />
        </div>
      ) : servers.length > 0 ? (
        _.map(servers, (server, i) => (
          <Paper elevation={2} className={`${classes.paper} ${i % 2 ? classes.even : ''}`} key={server.name}>
            <Grid container spacing={2}>
              <Grid item className={classes.flexFull}>
                <Typography className={classes.serverName} variant="h6">
                  <LedIndicator online={_.toInteger(server.online) ? !server.overdue : false} /> {server.name}
                </Typography>
                <div className={classes.flexContainer}>
                  <Typography variant="subtitle2" className={classes.infoLeftContainer}>
                    {t('description')}:
                  </Typography>
                  <Typography variant="subtitle2">{server.description}</Typography>
                </div>
                <div className={classes.flexContainer}>
                  <Typography variant="subtitle2" className={classes.infoLeftContainer}>
                    {t('site')}:
                  </Typography>
                  <Typography variant="subtitle2">
                    {server.region.toUpperCase()} - {server.clientName} - {server.siteName}
                  </Typography>
                </div>
                <div className={classes.flexContainer}>
                  <Typography variant="subtitle2" className={classes.infoLeftContainer}>
                    {t('location')}:
                  </Typography>
                  <Typography variant="subtitle2">{getLocationString(server)}</Typography>
                </div>
                <div className={classes.flexContainer}>
                  <Typography variant="subtitle2" className={classes.infoLeftContainer}>
                    {t('software')}:
                  </Typography>
                  <Typography variant="subtitle2">{replaceError(server.software) || 'N/A'}</Typography>
                </div>
                <div className={classes.flexContainer}>
                  <Typography variant="subtitle2" className={classes.infoLeftContainer}>
                    {t('last_5_backups')}:
                  </Typography>
                  <Last5Backup lastBackups={getLast5Backups(server.backup_history)} />
                </div>
              </Grid>
              <Grid item className={classes.buttonItem}>
                <ConnectButton server={server} type="control" />
              </Grid>
            </Grid>
            {failingChecks[server.serverid] && (
              <Typography variant="subtitle2" color="error" className={classes.failingChecksBanner}>
                {t('there are failing checks', { noOfChecks: failingChecks[server.serverid].length })}
                <ExpandMoreIcon
                  className={`${classes.failincChecksArrow} ${
                    expandedChecks.includes(server.serverid) ? classes.failingChecksArrowOpen : ''
                  }`}
                  onClick={toggleFailingCheck(server.serverid)}
                />
              </Typography>
            )}
            {expandedChecks.includes(server.serverid) &&
              _.map(failingChecks[server.serverid], check => (
                <Card className={classes.failingChecksContainer} key={`${server.serverid}${check.checkid}`}>
                  <Typography className={classes.failingChecksText}>{check.formatted_output}</Typography>
                  <Typography className={classes.failingChecksText}>{check.description}</Typography>
                </Card>
              ))}
          </Paper>
        ))
      ) : (
        <Typography align="center" variant="subtitle2">
          {t('no servers available')}
        </Typography>
      )}
    </Container>
  );
};

export default withFirebase(ConnectPage);
