import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { APP_GREEN_COLOR, APP_RED_COLOR } from '../../constants/const';

const useStyles = makeStyles(() => ({
  backupRootContainer: {
    display: 'flex',
    columnGap: '3px',
  },
  backupContainer: {
    width: '15px',
    height: '20px',
  },
  passContainer: {
    background: APP_GREEN_COLOR,
  },
  failContainer: {
    background: APP_RED_COLOR,
  },
}));

const Last5BackupContainer = props => {
  const classes = useStyles();
  return (
    <div className={classes.backupRootContainer}>
      {props.lastBackups?.map((lastBackup, index) => {
        return (
          <Tooltip title={lastBackup.date + ' ' + lastBackup.status} key={index}>
            <div
              className={
                (lastBackup.status === 'PASS' || lastBackup.status === 'NOTRUN'
                  ? classes.passContainer
                  : classes.failContainer) +
                ' ' +
                classes.backupContainer
              }
            />
          </Tooltip>
        );
      })}
    </div>
  );
};
export default Last5BackupContainer;
