import React, { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withFirebase } from '../Firebase';
import TableIcons from '../../constants/tableIcons';
import { useTranslation } from 'react-i18next';
import MaterialTable from '../MaterialTable/MaterialTable';
import 'jspdf-autotable';
import Button from '@material-ui/core/Button';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import { useDispatch } from 'react-redux';
import { showToast } from '../../store/actions/toastAction';

const useStyles = makeStyles(theme => ({
  table: {
    margin: theme.spacing(2, 0),
  },
  selectContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
  },
  selectLabel: {
    marginRight: '15px',
  },
  datePicker: {
    flex: 1,
  },
}));

const BillingReportTable = ({ data, columns }) => {
  const { t } = useTranslation();
  return <MaterialTable icons={TableIcons} title={t('monthly_billing_reports')} columns={columns} data={data} />;
};

const BillingReportPage = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [reports, setReports] = useState([]);
  const dispatch = useDispatch();

  const downloadPdf = site => {
    window.open(site.url, '_blank');
  };

  const columns = [
    {
      title: t('name'),
      field: 'fileName',
    },
    {
      title: t('download'),
      render: rowData => (
        <Button variant="contained" color="default" startIcon={<DownloadIcon />} onClick={() => downloadPdf(rowData)}>
          {t('pdf_report')}
        </Button>
      ),
    },
  ];

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const reportSnapshot = await props.firebase.billingReports().get();
        const reports = reportSnapshot.docs.map(doc => doc.data());

        setReports(reports);
        setLoading(false);
      } catch (error) {
        dispatch(showToast(`${error}`, 'error'));
        setLoading(false);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container maxWidth="xl">
      {!loading ? (
        <div>
          <div className={classes.table}>
            <BillingReportTable columns={columns} data={reports} />
          </div>
        </div>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress />
        </div>
      )}
    </Container>
  );
};

export default withFirebase(BillingReportPage);
