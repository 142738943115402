import React, { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withFirebase } from '../Firebase';
import TableIcons from '../../constants/tableIcons';
import { useTranslation } from 'react-i18next';
import MaterialTable from '../MaterialTable/MaterialTable';
import 'jspdf-autotable';
import Button from '@material-ui/core/Button';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import { addDays, endOfMonth, format, startOfMonth } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from '../../store/actions/toastAction';
import { checkIfPermissionIsGranted, getSitesFromDatabase, isAuthUserAdmin, REGION_LOOKUP } from '../../utils/common';
import GenerateTestReportDialog from './dialogs/GenerateTestReportDialog';

const useStyles = makeStyles(theme => ({
  table: {
    margin: theme.spacing(2, 0),
  },
  selectContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '15px',
  },
  selectLabel: {
    marginRight: '15px',
  },
  datePicker: {
    flex: 1,
  },
}));

const ReportTable = ({ data, columns }) => {
  const { t } = useTranslation();
  return (
    <MaterialTable
      icons={TableIcons}
      title={t('monthly_reports')}
      columns={columns}
      data={data}
      options={{
        filtering: true,
      }}
    />
  );
};

const ReportPage = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [selectedDate, handleDateChange] = useState(new Date());
  const [showGenerateTestReport, setShowGenerateTestReport] = useState(false);
  const [sites, setSites] = useState([]);
  const [loading, setLoading] = useState(true);
  const [reports, setReports] = useState([]);
  const authUser = useSelector(state => state.user);
  const dispatch = useDispatch();

  const getSites = async () => {
    const sites = (await getSitesFromDatabase(props.firebase, authUser)).map(site => {
      return {
        id: site.id,
        title: `${site.clientName} - ${site.name}`,
      };
    });
    setSites(sites);
  };

  const downloadPdf = site => {
    window.open(site.url, '_blank');
  };

  const columns = [
    {
      title: t('region'),
      field: 'region',
      width: '60px',
      lookup: REGION_LOOKUP,
      render: rowData => <div>{rowData.region?.toUpperCase()}</div>,
    },
    {
      title: t('client'),
      field: 'clientName',
    },
    {
      title: t('site'),
      field: 'siteName',
    },
    {
      title: t('language'),
      field: 'language',
    },
    {
      title: t('date'),
      field: 'date',
      render: rowData => {
        return format(new Date(parseInt(rowData.date) - 24 * 60 * 60 * 1000), 'MMMM yyyy');
      },
    },
    {
      title: t('download'),
      render: rowData => (
        <Button variant="contained" color="default" startIcon={<DownloadIcon />} onClick={() => downloadPdf(rowData)}>
          {t('pdf_report')}
        </Button>
      ),
    },
  ];

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        if (isAuthUserAdmin(authUser)) {
          await getSites();
        }
        let reportSnapshot = props.firebase.reports();
        let reports = [];
        // Added 1 day because it takes about 10 hours to generate full monthly report and
        // generated report date can be plus 1
        const startDate = addDays(startOfMonth(selectedDate), 1).valueOf();
        const endDate = addDays(endOfMonth(selectedDate), 1).valueOf();
        // Multiple inequality operators can't be used together (`in` and `>` condition)
        // To achieve this, we need to use Firestore composite index but I don't think it's worth
        // Just need to get list of all reports for normal user and filter it after we get result
        if (isAuthUserAdmin(authUser)) {
          reports = (await reportSnapshot.where('date', '>', startDate).where('date', '<', endDate).get()).docs.map(
            doc => doc.data(),
          );
        } else {
          const sitesSnapshot = await props.firebase
            .sites()
            .where('administrators', 'array-contains', `${props.firebase.auth.currentUser.uid}`)
            .get();
          const siteIds = sitesSnapshot.docs.map(doc => doc.data().siteid);
          if (!siteIds.length) {
            setReports([]);
            setLoading(false);
            return;
          }
          reports = (await reportSnapshot.where('siteid', 'in', siteIds).get()).docs
            .map(doc => doc.data())
            .filter(report => report.date > startDate && report.date < endDate);
        }
        setReports(reports);
        setLoading(false);
      } catch (error) {
        dispatch(showToast(`${error}`, 'error'));
        setLoading(false);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  return (
    <Container maxWidth="xl">
      {checkIfPermissionIsGranted('showReport', authUser) && (
        <Button variant="contained" color="primary" onClick={() => setShowGenerateTestReport(true)}>
          {t('generate_monthly_test_report')}
        </Button>
      )}

      <GenerateTestReportDialog
        show={showGenerateTestReport}
        toggler={setShowGenerateTestReport}
        sites={sites}
        firebase={props.firebase}
      />
      <div className={classes.selectContainer}>
        <span className={classes.selectLabel}>{t('select_report_month')}</span>
        <div className={classes.datePicker}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              id="month"
              views={['year', 'month']}
              label={t('month')}
              name="month"
              fullWidth
              onChange={handleDateChange}
              value={selectedDate}
              disabled={loading}
            />
          </MuiPickersUtilsProvider>
        </div>
      </div>
      {!loading ? (
        <div>
          <div className={classes.table}>
            <ReportTable columns={columns} data={reports} />
          </div>
        </div>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress />
        </div>
      )}
    </Container>
  );
};

export default withFirebase(ReportPage);
