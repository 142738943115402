import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { showToast } from '../../store/actions/toastAction';
import * as API from '../../constants/api';
import { showErrorAlert } from '../../utils/common';
import AdornedButton from '../Button/AdornedButton';
import axios from 'axios';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const ForgotPasswordPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          {t('password reset')}
        </Typography>
        <ForgotPasswordForm />
      </div>
    </Container>
  );
};

const INITIAL_STATE = {
  email: '',
  error: null,
};

const ForgotPasswordFormBase = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({ ...INITIAL_STATE });
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onSubmit = async event => {
    event.preventDefault();
    const { email } = formData;

    setLoading(true);
    try {
      await axios.post(API.SEND_PASSWORD_RESET_EMAIL, {
        email,
      });
      dispatch(showToast(t('check email')));
      setFormData({ ...INITIAL_STATE });
    } catch (error) {
      showErrorAlert(t('try_again'));
    } finally {
      setLoading(false);
    }
  };

  const onChange = event => {
    event.persist();
    setFormData(prevFormData => ({
      ...prevFormData,
      [event.target.name]: event.target.value,
    }));
  };

  const { email, error } = formData;
  const isInvalid = email === '';

  return (
    <form className={classes.form} onSubmit={onSubmit}>
      <TextField
        name="email"
        value={formData.email || ''}
        onChange={onChange}
        fullWidth
        required
        type="email"
        label={t('email')}
        variant="outlined"
        margin="normal"
        autoComplete="email"
        autoFocus
      />
      <AdornedButton
        disabled={isInvalid || loading}
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        loading={loading}
      >
        {t('reset password')}
      </AdornedButton>
      <Link href={ROUTES.SIGN_IN} variant="body2">
        {t('sign in')}
      </Link>

      {error && (
        <Typography variant="subtitle2" color="error">
          {error.message}
        </Typography>
      )}
    </form>
  );
};

export default ForgotPasswordPage;

const ForgotPasswordForm = withFirebase(ForgotPasswordFormBase);

export { ForgotPasswordForm };
