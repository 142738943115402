import { addSeconds, format } from 'date-fns';

export const DEVICE_EXPORT_COLUMNS = [
  'Client ID',
  'Site ID',
  'Device ID',
  'Region',
  'Client',
  'Site',
  'Name',
  'Last Response',
  'Serial Number',
  'Last 5 Backups',
  'CirrusPRO Database Size',
  'CPU',
  'RAM',
  'HDD',
  'Manufacturer',
  'Model',
  'OS',
  'State',
  'Messaging Agent',
  'Country',
  'City',
  'Software',
  'Last Boot Time',
  'Download speed',
  'Upload speed',
  'Warranty Status',
  'Warranty Check Status',
  'Warranty Check Date',
  'Warranty Start Date',
  'Warranty End Date',
  'RBCustom1',
  'RBCustom2',
  'RBCustom3',
];

export const SOFTWARE_ASSET_EXPORT_COLUMNS = [
  'Client ID',
  'Site ID',
  'Device ID',
  'Region',
  'Client',
  'Site',
  'Name',
  'RainBird Software',
  'Software',
  'Version',
  'Install Date',
];

export const getLastResponse = (time, offset) => {
  const date = new Date(time);
  if (isNaN(date) || typeof offset === 'object' || isNaN(Number(offset))) {
    return;
  }
  const updatedDate = addSeconds(date, -Number(offset));
  return format(updatedDate, 'yyyy-MM-dd');
};

export const getFormatDate = timestamp => {
  if (!timestamp) {
    return;
  }
  return format(timestamp, 'yyyy-MM-dd');
};

export const getFormatDateTime = timestamp => {
  if (!timestamp) {
    return;
  }
  return format(timestamp, 'yyyy-MM-dd hh:mm:ss');
};

export const getDeviceExportRowData = row => {
  return [
    row.clientid,
    row.siteid,
    row.serverid,
    row.region?.toUpperCase(),
    row.clientName,
    row.siteName,
    row.name,
    getLastResponse(row.utc_apt, row.utc_offset),
    row.device_serial,
    row.last_backups?.map(backup => backup.status).join(' | '),
    row.cirrusProDatabaseSize,
    row.cpu,
    `${(row.ram ?? 0) / 1024 / 1024} MB`,
    row.hdd,
    row.manufacturer,
    row.model,
    row.os,
    row.state,
    row.pulse,
    row.country,
    row.city,
    row.software,
    getFormatDateTime(row.last_boot_time * 1000),
    row.download_speed,
    row.upload_speed,
    row.warranty,
    row.warrantyCheck,
    getFormatDate(row.warrantyCheckDate),
    getFormatDate(row.warrantyStartDate),
    getFormatDate(row.warrantyEndDate),
    row.rbCustom1Check,
    row.rbCustom2Check,
    row.rbCustom3Check,
  ];
};

export const getSoftwareAssetExportRowData = row => {
  let softwareAssets = [];
  try {
    softwareAssets = JSON.parse(row.softwareAssets);
  } catch (e) {
    console.log(`Error occurred while parsing software assets`);
  }

  if (softwareAssets.length === 0) {
    softwareAssets = [{}];
  }
  try {
    return softwareAssets.map(asset => {
      const installDate = new Date(asset.install_date);
      let installDateString = '';
      if (asset.install_date && !isNaN(installDate)) {
        installDateString = format(installDate, 'yyyy-MM-dd');
      }
      return [
        row.clientid,
        row.siteid,
        row.serverid,
        row.region?.toUpperCase(),
        row.clientName,
        row.siteName,
        row.name,
        row.software,
        asset.name,
        typeof asset.version === 'string' ? asset.version : '',
        installDateString,
      ];
    });
  } catch (e) {
    return [];
  }
};
