import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';

import { withFirebase } from '../Firebase';
import TableIcons from '../../constants/tableIcons';
import EditOrganization from './dialogs/EditOrganization';
import AddOrganization from './dialogs/AddOrganization';
import UpdateUserDialog from '../Users/dialogs/UpdateUser';
import { useTranslation } from 'react-i18next';
import MaterialTable from '../MaterialTable/MaterialTable';
import { getSitesFromDatabase } from '../../utils/common';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  link: {
    cursor: 'pointer',
    fontWeight: 'bold',
  },
  nameLink: {
    cursor: 'pointer',
    display: 'block',
    fontSize: 14,
  },
  table: {
    margin: theme.spacing(2, 0),
  },
  select: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
  adminLine: {
    display: 'inline-block',
    verticalAlign: 'top',
  },
  adminRemove: {
    display: 'inline-block',
    verticalAlign: 'top',
    float: 'right',
  },
  adminBlock: {
    marginBottom: 10,
    borderBottom: '1px solid #e0e0e0',
  },
}));

const OrganizationsTable = ({ data, columns }) => {
  const { t } = useTranslation();

  return <MaterialTable icons={TableIcons} title={t('organizations')} columns={columns} data={data} />;
};

const OrganizationsPage = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const authUser = useSelector(state => state.user);
  const [users, setUsers] = useState([]);
  const [sites, setSites] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [showAdd, setShowAdd] = useState(false);

  // Update user related code
  const [showUpdateUser, setShowUpdateUser] = useState(false);
  const [updateUser, setUpdateUser] = useState(null);

  useEffect(() => {
    setShowUpdateUser(true);
  }, [updateUser]);

  const onEditClose = () => {
    setSelectedOrganization(null);
    setShowEdit(false);
  };

  const columns = [
    {
      title: t('organization'),
      field: 'name',
      cellStyle: {
        verticalAlign: 'top',
      },
      render: rowData => (
        <Link onClick={() => setSelectedOrganization(rowData)} className={classes.link}>
          {rowData.name} {rowData.disabled ? ' - Disabled' : ''}
        </Link>
      ),
    },
    {
      title: t('members'),
      field: 'members',
      render: rowData => (
        <div>
          {_.map(rowData.members, member => (
            <div key={member.uid} className={classes.adminBlock}>
              <div className={classes.adminLine}>
                <Link className={classes.nameLink} onClick={() => setUpdateUser(member)}>
                  {member.name || ''}
                </Link>
                <Typography variant="caption">{member.email || '-'}</Typography>
              </div>
            </div>
          ))}
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (selectedOrganization) setShowEdit(true);
  }, [selectedOrganization]);

  useEffect(() => {
    const updatedOrganizations = _.map(organizations, organization => {
      organization.members = [];
      return organization;
    });

    _.forEach(users, user => {
      _.forEach(updatedOrganizations, organization => {
        if ('organization' in user && user.organization.id === organization.uid) {
          organization.members.push(user);
        }
      });
    });
  }, [users, organizations]);

  const getOrganizations = async () => {
    const organizationSnapshot = await props.firebase.organizations().get();
    const newOrganizations = organizationSnapshot.docs.map(doc => {
      const organizationData = doc.data();
      return {
        uid: doc.id,
        id: doc.id,
        members: [],
        ...organizationData,
      };
    });
    setOrganizations(newOrganizations);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        await getOrganizations();
        const userSnapshot = await props.firebase.users().get();
        const newUsers = userSnapshot.docs.map(doc => {
          const user = doc.data();
          return {
            uid: doc.id,
            id: doc.id,
            ...user,
          };
        });
        setUsers(newUsers);

        setSites(await getSitesFromDatabase(props.firebase, authUser));
        setError(null);
        setLoading(false);
      } catch (e) {
        setError(t('connection error'));
        setLoading(false);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container maxWidth="md">
      {!loading ? (
        <div>
          <Button variant="contained" color="primary" onClick={() => setShowAdd(true)}>
            {t('new organization')}
          </Button>
          {updateUser ? (
            <UpdateUserDialog
              setUser={setUpdateUser}
              user={updateUser}
              firebase={props.firebase}
              organizations={organizations}
              sites={sites}
              show={showUpdateUser}
              toggler={setShowUpdateUser}
            />
          ) : null}
          {selectedOrganization ? (
            <EditOrganization
              firebase={props.firebase}
              users={users}
              reload={getOrganizations}
              setOrganization={setSelectedOrganization}
              organization={selectedOrganization}
              show={showEdit}
              toggler={onEditClose}
            />
          ) : null}
          <AddOrganization firebase={props.firebase} show={showAdd} reload={getOrganizations} toggler={setShowAdd} />
          <div className={classes.table}>
            <OrganizationsTable columns={columns} data={organizations} users={users} />
          </div>
          {error ? (
            <Typography variant="subtitle2" color="error">
              {error}
            </Typography>
          ) : null}
        </div>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress />
        </div>
      )}
    </Container>
  );
};

export default withFirebase(OrganizationsPage);
