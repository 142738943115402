import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import { useTranslation } from 'react-i18next';
import * as API from '../../constants/api';
import AdornedButton from '../Button/AdornedButton';
import { useDispatch } from 'react-redux';
import { showToast } from '../../store/actions/toastAction';
import axios from 'axios';
import { getUserData } from '../../utils/common';
import { setUserSigning, updateUser } from '../../store/actions/userAction';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const SignInPage = props => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Container component="main" maxWidth="xs">
      {/* <CssBaseline /> */}
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          {t('sign in')}
        </Typography>
        <SignInForm firebase={props.firebase} />
      </div>
    </Container>
  );
};

const INITIAL_STATE = {
  email: '',
  password: '',
};

const SignInForm = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const onSubmit = async event => {
    event.preventDefault();
    const { email, password } = formData;

    setLoading(true);
    try {
      await axios.post(API.LOGIN, { email, password });
      // We are using a custom login logic because the default Firebase authentication does not
      // include role-based login checks or sending 2FA codes.
      // First, call the login API to check if the user is authorized to log in.
      // Then, call the Firebase doSignInWithEmailAndPassword method to let the app know that Firebase is authorized
      // (this is necessary because Firestore heavily depends on Firebase Authentication).
      // Sending a 2FA code cannot be done in the Login endpoint because the 2FA token relies on the Firebase refresh token.
      dispatch(setUserSigning(true));
      const res = await props.firebase.doSignInWithEmailAndPassword(email, password);
      await axios.post(API.SEND_TWO_FACTOR_CODE);
      const userData = await getUserData(props.firebase, res.user.uid);
      dispatch(updateUser(userData));
      setFormData({ ...INITIAL_STATE });
      history.push(ROUTES.CONNECT);
    } catch (error) {
      dispatch(showToast(error.message ?? error?.response?.data, 'error'));
    } finally {
      dispatch(setUserSigning(false));
      setLoading(false);
    }
  };

  const onChange = event => {
    event.persist();
    setFormData(prevFormData => ({
      ...prevFormData,
      [event.target.name]: event.target.value,
    }));
  };

  const { email, password } = formData;
  const isInvalid = password === '' || email === '';

  return (
    <form className={classes.form} noValidate onSubmit={onSubmit}>
      <TextField
        name="email"
        value={formData.email || ''}
        onChange={onChange}
        fullWidth
        required
        type="email"
        label={t('email')}
        variant="outlined"
        margin="normal"
        autoComplete="email"
        autoFocus
      />
      <TextField
        name="password"
        margin="normal"
        required
        fullWidth
        value={password || ''}
        onChange={onChange}
        type="password"
        label={t('password')}
        variant="outlined"
      />
      <AdornedButton
        disabled={isInvalid}
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        loading={loading}
        className={classes.submit}
      >
        {t('sign in')}
      </AdornedButton>
      <Link href={ROUTES.FORGOT_PASSWORD} variant="body2">
        {t('forgot password?')}
      </Link>
    </form>
  );
};

export default withFirebase(SignInPage);
