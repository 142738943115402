import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import phone from 'phone';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTranslation } from 'react-i18next';
import * as API from '../../../constants/api';
import { regions } from '../../../constants/regions';
import LanguageSelect from '../../LanguageSelect/LanguageSelect';
import { stripPhone } from '../../../utils/phoneNumber';
import AdornedButton from '../../Button/AdornedButton';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from '../../../store/actions/toastAction';
import axios from 'axios';
import { APP_PERMISSIONS, APP_RESOURCES, getRoleListFromRole } from '../../../utils/common';
import { Checkbox, FormControlLabel } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  select: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
  divider: {
    margin: theme.spacing(1, 0),
  },

  cirrusProOnly: {
    margin: '20px 6px',
  },
}));

export default ({ reload, user, setUser, show, toggler, organizations, sites, firebase }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const sitesArray = _.map(sites, site => ({ uid: site.uid, title: `${site.clientName} - ${site.name}` }));
  user['sites'] = _.map(
    _.filter(sites, site => 'administrators' in site && site.administrators.includes(user.uid)),
    site => ({ uid: site.uid, title: `${site.clientName} - ${site.name}` }),
  );
  user.organization_id = user.organization?.id || '';

  const dispatch = useDispatch();
  const authUser = useSelector(state => state.user);
  const [userData, setUserData] = useState({});
  const [showDelete, setShowDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [error, setError] = useState('');
  const [loaded, setLoaded] = useState(false);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [selectedResources, setSelectedResources] = useState([]);
  const initialUser = user;
  const roles = getRoleListFromRole(authUser.role);

  useEffect(() => {
    const permissions = (user?.permissions?.split(',') ?? []).filter(permission => !!permission);
    const resources = (user?.resources?.split(',') ?? []).filter(resource => !!resource);
    setSelectedPermissions(permissions);
    setSelectedResources(resources);
    setUserData(user);
  }, [user]);

  const onClose = () => {
    setUser(null);
    toggler(false);
  };

  const onChange = event => {
    event.persist();
    setUserData(prevUser => ({
      ...prevUser,
      [event.target.name]: event.target.value,
    }));
  };

  const onCheckboxChange = event => {
    event.persist();
    event.preventDefault();
    setUserData(prevUser => ({
      ...prevUser,
      [event.target.name]: event.target.checked,
    }));
  };

  const onPhoneChange = event => {
    event.persist();
    setUserData(prevUser => ({
      ...prevUser,
      phoneNumber: stripPhone(event.target.value),
    }));
  };

  const onTagsChange = (event, values) => {
    setUserData(prevUser => ({
      ...prevUser,
      sites: _.map(values, value => value),
    }));
  };

  const validatePhone = number => {
    if (!number) {
      setError(null);
      return;
    }
    setError(phone(number).isValid ? null : t('invalid phone format'));
  };

  const resetPassword = async (email, language) => {
    try {
      setResetPasswordLoading(true);
      await axios.post(API.SEND_PASSWORD_RESET_EMAIL, {
        email,
        language,
      });
    } catch {
      dispatch(showToast(t('try_again'), 'error'));
    } finally {
      setResetPasswordLoading(false);
    }
  };

  const deleteUser = async () => {
    try {
      setDeleting(true);
      setShowDelete(false);
      await axios.post(API.DELETE_USER, {
        uid: userData.uid,
      });
      dispatch(showToast(t(`user_delete_success`)));
      if (reload) {
        await reload();
      }
      onClose();
    } catch {
      dispatch(showToast(`Error occurred while removing ${userData.email}.`, 'error'));
    } finally {
      setDeleting(false);
    }
  };

  const updateUser = async () => {
    try {
      const postData = {
        uid: userData.uid,
        name: userData.name,
        email: userData.email,
        phoneNumber: userData.phoneNumber ?? '',
        region: userData.region,
        role: userData.role,
        permissions: selectedPermissions.join(','),
        resources: selectedResources.join(','),
        language: userData.language,
        organization: userData.organization_id ?? '',
        sites: userData.sites.map(site => site.uid),
        cirrusProOnly: userData.cirrusProOnly,
      };
      setLoading(true);
      await axios.post(API.UPDATE_USER, postData);
      if (reload) {
        await reload();
      }
      dispatch(showToast(t('user updated')));
      toggler(false);
    } catch (e) {
      dispatch(showToast(`${e.response.data ? e.response.data.message : e.response}`, 'error'));
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoaded(true);
  }, [userData]);

  const isInvalid =
    !userData.email ||
    !userData.name ||
    !userData.organization_id ||
    !userData.role ||
    !userData.language ||
    (_.isEqual(initialUser, userData) &&
      userData.permissions === selectedPermissions.join(',') &&
      userData.resources === selectedResources.join(',')) ||
    (userData.phoneNumber && !phone(userData.phoneNumber).isValid);

  return userData && loaded ? (
    <>
      <Dialog open={show || false} onClose={onClose}>
        <DialogTitle>{userData.name}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            id="name"
            label={t('name')}
            type="text"
            name="name"
            required
            fullWidth
            value={userData.name || ''}
            onChange={onChange}
            disabled={loading}
            autoFocus
          />
          <TextField
            margin="dense"
            id="email"
            label={t('email')}
            type="email"
            required
            name="email"
            fullWidth
            value={userData.email || ''}
            onChange={onChange}
            disabled={loading}
          />
          {userData.uid !== firebase.auth.currentUser.uid && (
            <div className={classes.select}>
              <FormControl fullWidth={true}>
                <InputLabel id="role-select-label">{t('role') + ' *'}</InputLabel>
                <Select
                  labelId="role-select-label"
                  id="role-select"
                  name="role"
                  onChange={onChange}
                  value={userData.role}
                  fullWidth
                  disabled={loading}
                >
                  {_.map(roles, role => (
                    <MenuItem key={role} value={role}>
                      {t(role)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
          {userData.uid !== firebase.auth.currentUser.uid && userData.role === 'admin' && (
            <>
              <div className={classes.select}>
                <Autocomplete
                  multiple
                  id="permission-select"
                  options={APP_PERMISSIONS}
                  getOptionLabel={option => t(option)}
                  isOptionEqualToValue={(option, value) => value === option}
                  value={selectedPermissions}
                  onChange={(event, values) => setSelectedPermissions(values)}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="standard"
                      label={t('permissions')}
                      placeholder={t('permissions')}
                      name="permission"
                    />
                  )}
                />
              </div>
              <div className={classes.select}>
                <Autocomplete
                  multiple
                  id="resources-select"
                  options={APP_RESOURCES}
                  getOptionLabel={option => option.toUpperCase()}
                  isOptionEqualToValue={(option, value) => value === option}
                  value={selectedResources}
                  onChange={(event, values) => setSelectedResources(values)}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="standard"
                      label={t('resources')}
                      placeholder={t('resources')}
                      name="resources"
                    />
                  )}
                />
              </div>
              {selectedPermissions.includes('devices') && (
                <div className={classes.cirrusProOnly}>
                  <FormControlLabel
                    key="cirrus pro only"
                    control={
                      <Checkbox
                        checked={userData.cirrusProOnly ?? false}
                        onChange={onCheckboxChange}
                        style={{
                          padding: 0,
                        }}
                        name="cirrusProOnly"
                      />
                    }
                    label={t('cirrus_pro_only')}
                  />
                </div>
              )}
            </>
          )}
          <TextField
            margin="dense"
            id="email"
            label={t('phone number')}
            // type="email"
            name="phoneNumber"
            fullWidth
            value={userData.phoneNumber || ''}
            onChange={onPhoneChange}
            onBlur={event => event.target.value && validatePhone(event.target.value)}
            disabled={loading}
          />
          <Divider className={classes.divider} />
          <AdornedButton
            onClick={() => resetPassword(userData.email, userData.language)}
            variant="contained"
            color="primary"
            fullWidth
            disabled={loading || resetPasswordLoading}
            loading={resetPasswordLoading}
            style={{ marginBottom: '5px' }}
          >
            {t('send password reset email')}
          </AdornedButton>
          <Divider className={classes.divider} />
          <div className={classes.select}>
            <FormControl fullWidth={true}>
              <InputLabel id="organization-select-label">{t('organization') + ' *'}</InputLabel>
              <Select
                labelId="organization-select-label"
                id="organization-select"
                name="organization_id"
                onChange={onChange}
                value={userData.organization_id || ''}
                displayEmpty={true}
                fullWidth
                disabled={loading}
              >
                {_.map(organizations, organization => (
                  <MenuItem key={organization.uid} value={organization.uid}>
                    {organization.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className={classes.select}>
            <FormControl fullWidth={true}>
              <InputLabel id="region-select-label">{t('region') + ' *'}</InputLabel>
              <Select
                labelId="region-select-label"
                id="region-select"
                name="region"
                onChange={onChange}
                value={userData.region}
                displayEmpty={true}
                fullWidth
                disabled={loading}
              >
                {_.map(regions, region => (
                  <MenuItem key={region.code} value={region.code}>
                    {region.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className={classes.select}>
            <LanguageSelect value={userData.language} onChange={onChange} disabled={loading} required />
          </div>
          <div className={classes.select}>
            <Autocomplete
              multiple
              id="sites-standard"
              options={sitesArray}
              getOptionLabel={option => option.title}
              getOptionSelected={(option, value) => value.uid === option.uid}
              value={userData.sites}
              onChange={onTagsChange}
              renderInput={params => (
                <TextField {...params} variant="standard" label={t('sites')} placeholder={t('sites')} name="sites" />
              )}
            />
          </div>
          {error && (
            <Typography variant="subtitle2" color="error">
              {error}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          {userData.uid !== firebase.auth.currentUser.uid ? (
            <AdornedButton
              variant="contained"
              color="secondary"
              open={show || true}
              onClick={() => setShowDelete(true)}
              disabled={loading || deleting}
              loading={deleting}
            >
              {t('delete')}
            </AdornedButton>
          ) : null}
          <AdornedButton variant="contained" onClick={onClose} disabled={loading || resetPasswordLoading}>
            {t('cancel')}
          </AdornedButton>
          <AdornedButton
            variant="contained"
            color="primary"
            onClick={updateUser}
            disabled={isInvalid || loading || resetPasswordLoading}
            loading={loading}
          >
            {t('save')}
          </AdornedButton>
        </DialogActions>
      </Dialog>

      <Dialog open={showDelete || false} onClick={() => setShowDelete(false)}>
        <DialogContent>{t('delete user confirmation', { email: user.email })}</DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            open={show || true}
            onClick={() => setShowDelete(false)}
            disabled={loading}
          >
            {t('no')}
          </Button>
          <Button variant="contained" color="primary" onClick={() => deleteUser()} disabled={loading}>
            {t('yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  ) : null;
};
