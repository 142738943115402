import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { APP_GREEN_COLOR, APP_RED_COLOR } from '../../constants/const';

const useStyles = makeStyles(() => ({
  led: {
    width: 24,
    height: 24,
    backgroundColor: '#e6e6e6',
    borderRadius: '50%',

    display: 'inline-block',
    verticalAlign: 'middle',
    marginRight: 10,
  },
  ledGreen: {
    backgroundColor: APP_GREEN_COLOR,
  },
  ledRed: {
    backgroundColor: APP_RED_COLOR,
  },
}));

const LedIndicator = props => {
  const classes = useStyles();
  return <div className={`${classes.led} ${props.online ? classes.ledGreen : classes.ledRed}`} />;
};
export default LedIndicator;
