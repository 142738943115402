import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import AR from './translations/ar.json';
import JA from './translations/ja.json';
import KO from './translations/ko.json';
import EN from './translations/en.json';
import ES from './translations/es.json';
import FR from './translations/fr.json';
import IT from './translations/it.json';
import NL from './translations/nl.json';
import PL from './translations/pl.json';
import PT from './translations/pt.json';
import VI from './translations/vi.json';
import ZH from './translations/zh.json';
import DE from './translations/de.json';
import SV from './translations/sv.json';

export const resources = {
  ar: {
    translation: AR,
    name: 'arabic',
  },
  zh: {
    translation: ZH,
    name: 'chinese',
  },
  de: {
    translation: DE,
    name: 'german',
  },
  en: {
    translation: EN,
    name: 'english',
  },
  es: {
    translation: ES,
    name: 'spanish',
  },
  fr: {
    translation: FR,
    name: 'french',
  },
  it: {
    translation: IT,
    name: 'italian',
  },
  ja: {
    translation: JA,
    name: 'japanese',
  },
  ko: {
    translation: KO,
    name: 'korean',
  },
  nl: {
    translation: NL,
    name: 'dutch',
  },
  pl: {
    translation: PL,
    name: 'polish',
  },
  pt: {
    translation: PT,
    name: 'portuguese',
  },
  sv: {
    translation: SV,
    name: 'swedish',
  },
  vi: {
    translation: VI,
    name: 'vietnamese',
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',
  fallbackLng: 'en',
  debug: true,
  keySeparator: false,
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

export default i18n;
