import React, { useState } from 'react';
import _ from 'lodash';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { showToast } from '../../../store/actions/toastAction';
import AdornedButton from '../../Button/AdornedButton';

export default ({ users, site, firebase, show, toggler, reload }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [userToAdd, setUserToAdd] = useState({});
  const usersArray = _.filter(users, user => (site.administrators ? !site.administrators.includes(user.uid) : true));

  const addUser = async () => {
    if (!userToAdd?.uid) {
      return;
    }
    try {
      setLoading(true);
      firebase.site(site.uid).update({
        administrators: firebase.firestore.FieldValue.arrayUnion(userToAdd.uid),
      });
      await reload();
      dispatch(showToast(t('user_add_to_site_success')));
      toggler(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={show}
      onClose={() => {
        toggler(false);
      }}
    >
      <DialogTitle>{`${site.clientName || ''} - ${site.name || ''} - ${t('add administrator')}`}</DialogTitle>
      <DialogContent>
        <Autocomplete
          id="users-list"
          options={usersArray}
          getOptionLabel={option => `${option.name || ''} - ${option.email || ''}`}
          onChange={(event, newValue) => {
            setUserToAdd(newValue);
          }}
          renderInput={params => <TextField {...params} label={t('users')} variant="outlined" />}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => toggler(false)}>
          {t('cancel')}
        </Button>
        <AdornedButton
          variant="contained"
          color="primary"
          onClick={() => addUser()}
          disabled={loading}
          loading={loading}
        >
          {t('add')}
        </AdornedButton>
      </DialogActions>
    </Dialog>
  );
};
