import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import phone from 'phone';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';

import * as API from '../../../constants/api';
import { regions } from '../../../constants/regions';
import { useTranslation } from 'react-i18next';
import LanguageSelect from '../../LanguageSelect/LanguageSelect';
import { stripPhone } from '../../../utils/phoneNumber';
import { showToast } from '../../../store/actions/toastAction';
import { useDispatch, useSelector } from 'react-redux';
import AdornedButton from '../../Button/AdornedButton';
import axios from 'axios';
import { APP_PERMISSIONS, APP_RESOURCES, getRoleListFromRole } from '../../../utils/common';
import { Checkbox, FormControlLabel } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  select: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  cirrusProOnly: {
    margin: '20px 6px',
  },
}));

const ADD_USER_INITIAL_STATE = {
  email: '',
  name: '',
  phoneNumber: '',
  organization: '',
  region: '',
  sites: [],
  language: '',
};

export default ({ reload, show, toggler, organizations, sites, firebase }) => {
  const sitesArray = _.map(sites, site => ({ uid: site.uid, title: `${site.clientName} - ${site.name}` }));

  const classes = useStyles();
  const { t } = useTranslation();
  const authUser = useSelector(state => state.user);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [selectedResources, setSelectedResources] = useState([]);
  const roles = getRoleListFromRole(authUser.role);
  const [user, setUser] = useState(ADD_USER_INITIAL_STATE);

  const onChange = event => {
    event.persist();
    setUser(prevUser => ({
      ...prevUser,
      [event.target.name]: event.target.value,
    }));
  };

  const onCheckboxChange = event => {
    event.preventDefault();
    event.persist();
    setUser(prevUser => ({
      ...prevUser,
      [event.target.name]: event.target.checked,
    }));
  };

  useEffect(() => {
    if (show) {
      setUser(ADD_USER_INITIAL_STATE);
    }
  }, [show]);

  const onClose = () => {
    toggler(false);
  };

  const onPhoneChange = event => {
    event.persist();
    setUser(prevUser => ({
      ...prevUser,
      phoneNumber: stripPhone(event.target.value),
    }));
  };

  const onTagsChange = (event, values) => {
    setUser(prevUser => ({
      ...prevUser,
      sites: _.map(values, value => value.uid),
    }));
  };

  const validatePhoneNumber = event => {
    if (!event.target.value) {
      setErrorText(null);
      return;
    }
    setErrorText(phone(event.target.value).isValid ? null : t('invalid phone format'));
  };

  const createUser = async event => {
    try {
      event.preventDefault();
      setLoading(true);
      const postData = {
        name: user.name,
        email: user.email,
        phoneNumber: user.phoneNumber ?? '',
        region: user.region,
        role: user.role,
        permissions: selectedPermissions.join(','),
        resources: selectedResources.join(','),
        language: user.language || 'en',
        organization: user.organization ?? '',
        sites: user.sites,
        cirrusProOnly: user.cirrusProOnly,
      };
      await axios.post(API.CREATE_USER, postData);
      if (reload) {
        await reload();
      }
      dispatch(showToast(`User with email ${user.email} created.`));
      toggler(false);
    } catch (error) {
      dispatch(showToast(error.response.data.message, 'error'));
    } finally {
      setLoading(false);
    }
  };

  const isInvalid = !user.email || !user.name || !user.organization || !user.language || !user.region || !user.role;

  return (
    <Dialog open={show || false} onClose={onClose}>
      <DialogTitle>{t('new user')}</DialogTitle>
      <form noValidate onSubmit={createUser}>
        <DialogContent>
          <TextField
            margin="dense"
            id="name"
            label={t('name')}
            type="text"
            name="name"
            fullWidth
            required
            autoFocus
            value={user.name}
            onChange={onChange}
            disabled={loading}
          />
          <TextField
            margin="dense"
            id="email"
            label={t('email')}
            required
            type="email"
            name="email"
            fullWidth
            value={user.email}
            onChange={onChange}
            disabled={loading}
          />
          <div className={classes.select}>
            <FormControl fullWidth={true}>
              <InputLabel id="role-select-label">{t('role') + ' *'}</InputLabel>
              <Select
                labelId="role-select-label"
                id="role-select"
                name="role"
                onChange={onChange}
                value={user.role}
                fullWidth
                disabled={loading}
              >
                {_.map(roles, role => (
                  <MenuItem key={role} value={role}>
                    {t(role)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {user.role === 'admin' && (
            <>
              <div className={classes.select}>
                <Autocomplete
                  multiple
                  id="permission-select"
                  label="Permission"
                  options={APP_PERMISSIONS}
                  getOptionLabel={option => option.toUpperCase()}
                  isOptionEqualToValue={(option, value) => value === option}
                  value={selectedPermissions}
                  onChange={(event, values) => setSelectedPermissions(values)}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="standard"
                      label={t('permissions')}
                      placeholder={t('permissions')}
                      name="permission"
                    />
                  )}
                />
              </div>
              <div className={classes.select}>
                <Autocomplete
                  multiple
                  id="resources-select"
                  options={APP_RESOURCES}
                  getOptionLabel={option => option.toUpperCase()}
                  isOptionEqualToValue={(option, value) => value === option}
                  value={selectedResources}
                  onChange={(event, values) => setSelectedResources(values)}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="standard"
                      label={t('resources')}
                      placeholder={t('resources')}
                      name="resources"
                    />
                  )}
                />
              </div>
              {selectedPermissions.includes('devices') && (
                <div className={classes.cirrusProOnly}>
                  <FormControlLabel
                    key="cirrus pro only"
                    control={
                      <Checkbox
                        checked={user.cirrusProOnly ?? false}
                        onChange={onCheckboxChange}
                        style={{
                          padding: 0,
                        }}
                        name="cirrusProOnly"
                      />
                    }
                    label={t('cirrus_pro_only')}
                  />
                </div>
              )}
            </>
          )}
          <TextField
            margin="dense"
            id="phoneNumber"
            label={t('phone number')}
            name="phoneNumber"
            fullWidth
            value={user.phoneNumber}
            onChange={onPhoneChange}
            onBlur={validatePhoneNumber}
            disabled={loading}
          />
          <div className={classes.select}>
            <FormControl fullWidth={true}>
              <InputLabel id="organization-select-label">{t('organization') + ' *'}</InputLabel>
              <Select
                labelId="organization-select-label"
                id="organization-select"
                name="organization"
                onChange={onChange}
                value={user.organization}
                displayEmpty={true}
                fullWidth
                disabled={loading}
              >
                {_.map(organizations, organization => (
                  <MenuItem key={organization.uid} value={organization.uid}>
                    {organization.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className={classes.select}>
            <FormControl fullWidth={true}>
              <InputLabel id="region-select-label">{t('region') + ' *'}</InputLabel>
              <Select
                labelId="region-select-label"
                id="region-select"
                name="region"
                onChange={onChange}
                value={user.region}
                required
                displayEmpty={true}
                fullWidth
                disabled={loading}
              >
                {_.map(regions, region => (
                  <MenuItem key={region.code} value={region.code}>
                    {region.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className={classes.select}>
            <LanguageSelect value={user.language} onChange={onChange} required />
          </div>
          <div className={classes.select}>
            <FormControl fullWidth={true}>
              <Autocomplete
                multiple
                id="sites-standard"
                options={sitesArray}
                getOptionLabel={option => option.title}
                getOptionSelected={(option, value) => value.uid === option.uid}
                defaultValue={[]}
                onChange={onTagsChange}
                renderInput={params => (
                  <TextField {...params} variant="standard" label={t('sites')} placeholder={t('sites')} name="sites" />
                )}
              />
            </FormControl>
          </div>
          {errorText ? (
            <Typography variant="subtitle2" color="error">
              {errorText}
            </Typography>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={onClose} disabled={loading}>
            {t('close')}
          </Button>
          <AdornedButton
            variant="contained"
            color="primary"
            type="submit"
            disabled={!!(isInvalid || loading)}
            loading={loading}
          >
            {t('create')}
          </AdornedButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
