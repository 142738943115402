import React, { useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import Last5Backup from '../../Common/Last5Backup';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { APP_GREEN_COLOR } from '../../../constants/const';

const useStyles = makeStyles(theme => ({
  infoContainer: {
    display: 'flex',
    marginBottom: '15px',
  },
  infoLeftContainer: {
    width: '150px',
  },
  failingCheckList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    flex: '1',
  },
  failingCheckLabel: {
    whiteSpace: 'break-spaces',
    overflow: 'hidden',
    textOverflow: 'clip',
  },
  successFailingChecks: {
    color: APP_GREEN_COLOR,
  },
}));

export default ({ show, toggler, device, firebase }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [failingChecks, setFailingChecks] = useState([]);
  const classes = useStyles();

  const getFailingChecks = async () => {
    if (!device.id) {
      return;
    }
    setLoading(true);
    try {
      const failingChecksSnapshot = await firebase.failingChecks().where('deviceid', '==', device.id).get();
      setFailingChecks(failingChecksSnapshot.docs.map(doc => doc.data()));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getFailingChecks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device]);
  const onClose = () => {
    toggler(false);
  };

  return (
    <Dialog open={show || false} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{t('device_information')}</DialogTitle>
      <form>
        <DialogContent>
          {!loading ? (
            <div>
              <div className={classes.infoContainer}>
                <Typography variant="subtitle2" className={classes.infoLeftContainer}>
                  {t('name')}:
                </Typography>
                <Typography variant="subtitle2">{device.name}</Typography>
              </div>
              <div className={classes.infoContainer}>
                <Typography variant="subtitle2" className={classes.infoLeftContainer}>
                  {t('last_5_backups')}:
                </Typography>
                <Last5Backup lastBackups={device.last_backups} />
              </div>
              {device.cirrusProDatabaseSize ? (
                <div className={classes.infoContainer}>
                  <Typography variant="subtitle2" className={classes.infoLeftContainer}>
                    {t('cirrus_pro_database_size')}:
                  </Typography>
                  <Typography variant="subtitle2">{device.cirrusProDatabaseSize}</Typography>
                </div>
              ) : null}

              <div className={classes.infoContainer}>
                <Typography variant="subtitle2" className={classes.infoLeftContainer}>
                  {t('failing_checks')}:
                </Typography>
                <div className={classes.failingCheckList}>
                  {failingChecks.length !== 0 ? (
                    failingChecks.map((failingCheck, index) => (
                      <Typography variant="subtitle2" key={index} color="error" className={classes.failingCheckLabel}>
                        {failingCheck.description + ': ' + failingCheck.formatted_output}
                      </Typography>
                    ))
                  ) : (
                    <Typography variant="subtitle2" className={classes.successFailingChecks}>
                      {t('no_failing_checks_tasks')}
                    </Typography>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div style={{ textAlign: 'center' }}>
              <CircularProgress />
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={onClose}>
            {t('close')}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
