import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useTranslation } from 'react-i18next';
import { showToast } from '../../../store/actions/toastAction';
import { useDispatch } from 'react-redux';
import AdornedButton from '../../Button/AdornedButton';

const useStyles = makeStyles(theme => ({
  name: {
    marginBottom: theme.spacing(1),
  },
  adminLine: {
    marginRight: 20,
  },
  adminRemove: {
    marginLeft: 'auto',
    verticalAlign: 'top',
  },
  adminBlock: {
    display: 'flex',
    marginBottom: 10,
    borderBottom: '1px solid #e0e0e0',
  },
  select: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
}));

export default ({ organization, setOrganization, firebase, show, reload, toggler }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showRemoveMember, setShowRemoveMember] = useState(false);
  const [showDeleteOrganization, setShowDeleteOrganization] = useState(false);

  const onChange = event => {
    event.persist();
    setOrganization(prevOrganization => ({
      ...prevOrganization,
      [event.target.name]: event.target.name === 'disabled' ? event.target.checked : event.target.value,
    }));
  };

  const closeRemoveUser = () => {
    setSelectedUser(null);
    setShowRemoveMember(false);
  };

  const deleteOrganization = () => {
    setLoading(true);
    setShowDeleteOrganization(false);

    firebase
      .organization(organization.uid)
      .delete()
      .then(async () => {
        await reload();
        setOrganization(null);
      });
  };

  const updateOrganization = () => {
    setLoading(true);
    firebase
      .organization(organization.uid)
      .update({
        name: organization.name,
        region: organization.region,
        disabled: organization.disabled || false,
      })
      .then(async () => {
        await reload();
        setLoading(false);
        dispatch(showToast('Organization updated.'));
        toggler(false);
      })
      .catch(e => {
        setLoading(false);
        dispatch(showToast(`${e}`, 'error'));
      });
  };

  const removeUser = () => {
    setLoading(true);
    setShowRemoveMember(false);

    firebase
      .user(selectedUser.uid)
      .update({
        organization: firebase.firestore.FieldValue.delete(),
      })
      .then(() => {
        setOrganization(prevOrganization => ({
          ...prevOrganization,
          members: _.filter(organization.members, member => member.uid !== selectedUser.uid),
        }));
        firebase
          .sites()
          .where('administrators', 'array-contains', selectedUser.uid)
          .get()
          .then(querySnapshot => {
            querySnapshot.forEach(function (doc) {
              firebase.site(doc.id).update({
                administrators: firebase.firestore.FieldValue.arrayRemove(selectedUser.uid),
              });
            });
          });
        setLoading(false);
        setSelectedUser(null);
      })
      .catch(() => {
        setLoading(false);
        setSelectedUser(null);
        setShowRemoveMember(false);
      });
  };

  useEffect(() => {
    return setShowRemoveMember(true);
  }, [selectedUser]);

  const invalid = organization.members.length > 0;

  return (
    <div>
      <Dialog
        open={show}
        onClose={() => {
          toggler(false);
        }}
      >
        <DialogTitle>{organization.name || ''}</DialogTitle>
        <DialogContent>
          <div className={classes.name}>
            <TextField
              margin="dense"
              id="name"
              label={t('name')}
              type="name"
              name="name"
              fullWidth
              value={organization.name || ''}
              onChange={onChange}
              autoFocus
              disabled={loading}
            />
          </div>
          <Divider className={classes.divider} />
          {_.map(organization.members, member => (
            <div className={classes.adminBlock} key={member.uid}>
              <div className={classes.adminLine}>
                <Typography variant="subtitle2">
                  {(member.name || '-') + ` (${(member.region || '-').toUpperCase()})`}
                </Typography>
                <Typography variant="caption">{member.email || '-'}</Typography>
              </div>
              <div className={classes.adminRemove}>
                <IconButton onClick={() => setSelectedUser(member)} size="small" disabled={loading}>
                  <HighlightOffIcon color="error" />
                </IconButton>
              </div>
            </div>
          ))}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setShowDeleteOrganization(true)}
            disabled={invalid || loading}
          >
            {t('delete')}
          </Button>
          <Button variant="contained" onClick={() => toggler()} disabled={loading}>
            {t('cancel')}
          </Button>
          <AdornedButton
            variant="contained"
            color="primary"
            onClick={() => updateOrganization()}
            disabled={loading}
            loading={loading}
          >
            {t('save')}
          </AdornedButton>
        </DialogActions>
      </Dialog>

      {selectedUser ? (
        <Dialog open={showRemoveMember} onClose={() => closeRemoveUser()}>
          <DialogContent>
            {t('remove user from organization', {
              user: selectedUser ? `${selectedUser.name || '-'} (${selectedUser.email || '-'})` : '',
              organization: organization.name,
            })}
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="secondary" onClick={() => closeRemoveUser()} disabled={loading}>
              {t('no')}
            </Button>
            <Button variant="contained" color="primary" onClick={() => removeUser()} disabled={loading}>
              {t('yes')}
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}

      <Dialog open={showDeleteOrganization} onClose={() => setShowDeleteOrganization()}>
        <DialogContent>
          {t('delete organization confirmation', {
            orgName: organization.name,
          })}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={() => setShowDeleteOrganization()} disabled={loading}>
            {t('no')}
          </Button>
          <Button variant="contained" color="primary" onClick={() => deleteOrganization()} disabled={loading}>
            {t('yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
