export const SIGN_IN = '/';
export const HOME = '/home';
export const CONNECT = '/connect';
export const NOT_FOUND = '/404';
export const FORGOT_PASSWORD = '/forgot-pw';
export const CHANGE_PASSWORD = '/change-pw';
// Admin routes
export const USERS = '/users';
export const SITES = '/sites';
export const MONTHLY_BILLING = '/billing';
export const MESSAGING = '/messaging';
export const TEST = '/test';
export const DEVICES = '/devices';
export const TWO_FACTOR = '/2fa';
export const ORGANIZATIONS = '/organizations';
export const REPORTS = '/reports';
