import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/storage';

const useEmulator = process.env.REACT_APP_FIREBASE_USE_EMULATOR === 'true';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.auth = app.auth();
    this.db = app.firestore();
    this.rtdb = app.database();
    this.storage = app.storage();
    this.firestore = app.firestore;

    if (useEmulator) {
      this.db.useEmulator('localhost', process.env.REACT_APP_EMULATOR_FIREBASE_DOMAIN);
      this.auth.useEmulator(process.env.REACT_APP_EMULATOR_AUTH_DOMAIN);
      this.rtdb.useEmulator('localhost', process.env.REACT_APP_EMULATOR_DATABASE_DOMAIN);
    }
  }

  doSignInWithEmailAndPassword = (email, password) => this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordCodeVerification = code => this.auth.verifyPasswordResetCode(code);

  doPasswordReset = (code, password) => this.auth.confirmPasswordReset(code, password);

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

  // Firebase database - users
  user = uid => this.db.collection('Users').doc(uid);
  users = () => this.db.collection('Users');

  // Firebase database - users
  twoFactor = async token => (await this.db.collection('TwoFactorCodes').where('token', '==', token).get()).docs;

  // Firebase database - devices
  device = uid => this.db.collection('Devices').doc(uid);
  devices = () => this.db.collection('Devices');

  // Firebase database - monthly reports
  report = uid => this.db.collection('MonthlyReports').doc(uid);
  reports = () => this.db.collection('MonthlyReports');

  billingReports = () => this.db.collection('BillingReports');

  // Firebase database - sites
  site = uid => this.db.collection('Sites').doc(uid);
  sites = () => this.db.collection('Sites');

  // Firebase database - sites
  clients = () => this.db.collection('Clients');

  // Firebase database - devices
  organization = uid => this.db.collection('Organizations').doc(uid);
  organizations = () => this.db.collection('Organizations');

  // Firebase database - messaging
  message = uid => this.db.collection('Messages').doc(uid);
  messages = () => this.db.collection('Messages');

  // Firebase database - config
  config = uid => this.db.collection('Config').doc(uid);
  configs = () => this.db.collection('Config');

  // Firebase database - failing checks
  failingChecks = () => this.db.collection('FailingChecks');

  // Get storage ref
  storageRef = path => this.storage.ref().child(path);
}

export default Firebase;
