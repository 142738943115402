import React, { useState } from 'react';
import AdornedButton from '../../Button/AdornedButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import * as API from '../../../constants/api';
import { showToast } from '../../../store/actions/toastAction';
import { useDispatch } from 'react-redux';
import { downloadFileFromUrl } from '../../../utils/common';

const useStyles = makeStyles(theme => ({
  select: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
}));

export default ({ firebase, show, toggler, sites }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const [selectedSite, setSelectedSite] = useState({});

  const onClose = () => {
    toggler(false);
  };

  const generateReport = async event => {
    event.preventDefault();
    try {
      setLoading(true);
      const res = await axios.get(`${API.GENERATE_TEST_REPORT}?site_id=${selectedSite.id}`, { responseType: 'blob' });
      const blobURL = URL.createObjectURL(res.data);
      await downloadFileFromUrl(blobURL, `${selectedSite.title}.pdf`);
      toggler(false);
    } catch (e) {
      dispatch(showToast(`${e}`), 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={show || false} onClose={onClose}>
      <DialogTitle>{t('generate_monthly_test_report')}</DialogTitle>
      <form noValidate>
        <DialogContent>
          <Autocomplete
            id="sites-standard"
            options={sites}
            getOptionLabel={option => option.title}
            getOptionSelected={(option, value) => option.id === value.id}
            onChange={(event, value) => setSelectedSite(value)}
            value={selectedSite}
            className={classes.select}
            renderInput={params => (
              <TextField {...params} variant="standard" label={t('sites')} placeholder={t('sites')} name="sites" />
            )}
          />
        </DialogContent>
        <DialogActions>
          <AdornedButton
            variant="contained"
            color="primary"
            onClick={generateReport}
            disabled={loading || !selectedSite?.id}
            loading={loading}
          >
            {t('generate_monthly_test_report')}
          </AdornedButton>
          <AdornedButton variant="contained" onClick={onClose} disabled={loading}>
            {t('close')}
          </AdornedButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
