import React from 'react';
import ReactDOM from 'react-dom';

import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';

import './index.css';
import './i18n';
import * as serviceWorker from './serviceWorker';
import App from './components/App';
import Firebase, { FirebaseContext } from './components/Firebase';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { userReducer } from './store/reducers/userReducer';
import { APP_GREEN_COLOR, APP_RED_COLOR } from './constants/const';

const theme = createTheme({
  palette: {
    primary: {
      main: APP_GREEN_COLOR,
    },
    secondary: {
      main: APP_RED_COLOR,
    },
  },
});

const store = createStore(userReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <FirebaseContext.Provider value={new Firebase()}>
        <App />
      </FirebaseContext.Provider>
    </MuiThemeProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
