export const CREATE_USER = `create-user`;
export const DELETE_USER = `delete-user`;
export const GENERATE_PDF = `generate-pdf`;
export const UPDATE_USER = `update-user`;
export const TAKE_CONTROL = `take-control?`;
export const SEND_PASSWORD_RESET_EMAIL = `send-password-reset-email`;
export const SEND_FAILURE_CHECK = `send-test-failing-check`;
export const GENERATE_TEST_REPORT = `generate-test-monthly-report-for-site`;
export const UPDATE_SITE = `site`;
export const GET_FILTERED_LIST = `get-notification-eligible-devices?`;
export const LOGIN = `login`;
export const SEND_TWO_FACTOR_CODE = `send-2fa-code`;
export const VALIDATE_CODE = `verify-2fa-code`;
export const RESEND_CODE = `resend-2fa-code?`;
export const CONNECT_TO_TUNNEL = `connect-to-tunnel`;
export const GET_DEVICES_FOR_SELECT = `get-devices-for-select`;
export const GET_ACTIVE_TUNNELS = `active-tunnels`;
