import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),
  },
}));

const NotFoundPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Container className={classes.root} maxWidth="sm">
      <Typography align="center" variant="h2">
        {t('page not found')}
      </Typography>
    </Container>
  );
};

export default NotFoundPage;
