import React from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  spinner: {
    marginLeft: 5,
  },
});
const SpinnerAdornment = () => {
  const classes = useStyles();
  return <CircularProgress className={classes.spinner} size={20} />;
};
const AdornedButton = props => {
  const { children, loading, ...rest } = props;
  return (
    <Button {...rest} disabled={rest.disabled || loading}>
      {children}
      {loading && <SpinnerAdornment {...rest} />}
    </Button>
  );
};
export default AdornedButton;
