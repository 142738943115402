import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { regions } from '../../../constants/regions';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { showToast } from '../../../store/actions/toastAction';
import AdornedButton from '../../Button/AdornedButton';

const INITIAL_STATE = {
  name: '',
  region: regions[0].code,
};

export default ({ firebase, show, toggler, reload }) => {
  const { t } = useTranslation();

  const [organization, setOrganization] = useState(INITIAL_STATE);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const onChange = event => {
    event.persist();
    setOrganization(prevOrganization => ({
      ...prevOrganization,
      [event.target.name]: event.target.value,
    }));
  };

  const onClose = () => {
    toggler(false);
    setOrganization(INITIAL_STATE);
    setLoading(false);
  };

  const addOrganization = () => {
    setLoading(true);

    firebase
      .organizations()
      .add({
        name: organization.name,
        region: organization.region,
      })
      .then(async () => {
        await reload();
        dispatch(showToast(`Organization ${organization.name} created.`));
        setLoading(false);
        toggler(false);
        setOrganization(INITIAL_STATE);
      })
      .catch(error => {
        setLoading(false);
        dispatch(showToast(`${error.message}`, 'error'));
      });
  };

  const invalid = organization.name === '' || organization.name === undefined;

  return (
    <Dialog
      open={show}
      onClose={() => {
        onClose();
      }}
    >
      <DialogTitle>{t('new organization')}</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          id="name"
          label={t('name')}
          type="name"
          name="name"
          fullWidth
          value={organization.name || ''}
          onChange={onChange}
          autoFocus
          disabled={loading}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => onClose()} disabled={loading}>
          {t('cancel')}
        </Button>
        <AdornedButton
          variant="contained"
          color="primary"
          onClick={() => addOrganization()}
          disabled={loading || invalid}
          loading={loading}
        >
          {t('create')}
        </AdornedButton>
      </DialogActions>
    </Dialog>
  );
};
