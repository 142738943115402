import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { withFirebase } from '../Firebase';
import TableIcons from '../../constants/tableIcons';
import AddUserToSite from './dialogs/AddUserToSite';
import UpdateUserDialog from '../Users/dialogs/UpdateUser';
import { useTranslation } from 'react-i18next';
import MaterialTable from '../MaterialTable/MaterialTable';
import UpdateSiteDialog, { getSettingsArrayFromSite } from './dialogs/UpdateSite';
import EditIcon from '@material-ui/icons/Edit';
import { getSitesFromDatabase, REGION_LOOKUP } from '../../utils/common';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  nameLink: {
    cursor: 'pointer',
    display: 'block',
    fontSize: 14,
  },
  table: {
    margin: theme.spacing(2, 0),
  },
  select: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
  },
  adminLine: {
    display: 'inline-block',
    verticalAlign: 'top',
  },
  adminRemove: {
    display: 'inline-block',
    verticalAlign: 'top',
    float: 'right',
  },
  adminBlock: {
    marginBottom: 10,
    borderBottom: '1px solid #e0e0e0',
  },
  actionBlock: {
    display: 'flex',
  },
  pdfButton: {
    marginLeft: '10px',
  },
}));

const SitesTable = ({ data, columns }) => {
  const { t } = useTranslation();

  return (
    <MaterialTable
      icons={TableIcons}
      title={t('sites')}
      columns={columns}
      data={data}
      options={{
        filtering: true,
      }}
    />
  );
};

const UserCell = ({ adminData, setUpdateUser, deleteUser, rowData }) => {
  const classes = useStyles();

  const [hover, setHover] = useState(false);

  return (
    <div className={classes.adminBlock} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <div className={classes.adminLine}>
        <Link className={classes.nameLink} onClick={() => setUpdateUser(adminData)}>
          {adminData.name || ''}
        </Link>
        <Typography variant="caption">{`${adminData.email}` || ''}</Typography>
      </div>
      {hover ? (
        <div className={classes.adminRemove}>
          <IconButton size="small" onClick={() => deleteUser(rowData, adminData)}>
            <HighlightOffIcon color="error" />
          </IconButton>
        </div>
      ) : null}
    </div>
  );
};

const SitesPage = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const authUser = useSelector(state => state.user);
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sites, setSites] = useState([]);
  const [organizations, setOrganizations] = useState([]);

  const [showRemoveUser, setShowRemoveUser] = useState(false);
  const [showAddUser, setShowAddUser] = useState(false);
  const [showUpdateSite, setShowUpdateSite] = useState(false);
  const [userToRemove, setUserToRemove] = useState(null);
  const [selectedSite, setSelectedSite] = useState(null);

  // Update user related code
  const [showUpdateUser, setShowUpdateUser] = useState(false);
  const [updateUser, setUpdateUser] = useState(null);

  const updateSite = site => {
    setSelectedSite(site);
    setShowUpdateSite(true);
  };

  useEffect(() => {
    setShowUpdateUser(true);
  }, [updateUser]);

  const removeUser = () => {
    props.firebase.site(selectedSite.uid).update({
      administrators: props.firebase.firestore.FieldValue.arrayRemove(userToRemove.uid),
    });

    const newSites = [...sites].map(site => {
      if (site.uid === selectedSite.uid) {
        return {
          ...site,
          administrators: site.administrators?.filter(uId => uId !== userToRemove.uid),
        };
      }
      return site;
    });
    setSites(newSites);
    onClose();
  };

  const onClose = () => {
    setUserToRemove(null);
    setSelectedSite(null);
    setShowRemoveUser(false);
  };

  const deleteUser = (site, user) => {
    setSelectedSite(site);
    setUserToRemove(user);
  };

  const addUser = site => {
    setSelectedSite(site);
    setShowAddUser(true);
  };

  useEffect(() => {
    setShowRemoveUser(true);
  }, [userToRemove]);

  const columns = [
    {
      title: t('region'),
      field: 'region',
      width: '60px',
      lookup: REGION_LOOKUP,
      render: rowData => rowData.region?.toUpperCase(),
    },
    {
      title: t('client'),
      field: 'clientName',
    },
    {
      title: t('site'),
      field: 'name',
    },
    {
      title: t('users'),
      field: 'userList',
      width: 300,
      render: rowData => (
        <div>
          {_.map(rowData.administrators, admin => {
            const adminData = _.filter(users, user => user.uid === admin)[0];
            return adminData ? (
              <UserCell
                key={admin}
                admin={admin}
                adminData={adminData}
                setUpdateUser={setUpdateUser}
                deleteUser={deleteUser}
                rowData={rowData}
              />
            ) : null;
          })}
          <IconButton onClick={() => addUser(rowData)}>
            <PersonAddIcon />
          </IconButton>
        </div>
      ),
    },
    {
      title: t('feature_setting'),
      field: 'featureSetting',
      width: 150,
      render: rowData => {
        return getSettingsArrayFromSite(rowData.featureSetting)
          .map(key => t(`site_setting_${key}`))
          .join(' + ');
      },
    },
    {
      title: t('alert_setting'),
      field: 'alertSetting',
      width: 150,
      render: rowData => {
        return getSettingsArrayFromSite(rowData.alertSetting)
          .map(key => t(`site_setting_${key}`))
          .join(' + ');
      },
    },
    {
      title: '',
      render: rowData => (
        <div className={classes.actionBlock}>
          <IconButton onClick={() => updateSite(rowData)} size="small" disabled={loading}>
            <EditIcon color="primary" />
          </IconButton>
          {/*<Button variant="contained" color="default" startIcon={<PdfIcon />} onClick={() => doShowGeneratePdfDialog(rowData)} className={classes.pdfButton}>*/}
          {/*    { t('pdf_report') }*/}
          {/*</Button>*/}
        </div>
      ),
    },
  ];

  const reloadSite = updatedSite => {
    let updatedSites = [...sites];
    const index = sites.findIndex(site => site.siteid === updatedSite.siteid);
    updatedSites.splice(index, 1, updatedSite);
    setSites(updatedSites);
  };

  const fetchSites = async newUsers => {
    let sites = await getSitesFromDatabase(props.firebase, authUser);
    sites = sites.map(site => {
      const userList = (site.administrators ?? [])
        .map(userId => {
          const user = newUsers.find(user => user.uid === userId);
          return user ? `${user.name} ${user.email}` : '';
        })
        .join(' ');
      return {
        ...site,
        userList,
      };
    });
    setSites(sites);
  };

  const fetchData = async () => {
    try {
      const organizationSnapshot = await props.firebase.organizations().get();
      const newOrganizations = organizationSnapshot.docs.map(doc => {
        const organizationData = doc.data();
        return {
          uid: doc.id,
          id: doc.id,
          ...organizationData,
        };
      });
      setOrganizations(newOrganizations);

      const userSnapshot = await props.firebase.users().get();
      const newUsers = userSnapshot.docs.map(doc => {
        const user = doc.data();
        return {
          uid: doc.id,
          id: doc.id,
          region: doc.region ?? '',
          organization: doc.organization ?? '',
          ...user,
        };
      });
      setUsers(newUsers);

      await fetchSites(newUsers);
      setError(null);
      setLoading(false);
    } catch (e) {
      setError(`${e}`);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container maxWidth="lg">
      {!loading ? (
        <div>
          {selectedSite ? (
            <AddUserToSite
              firebase={props.firebase}
              reload={fetchData}
              users={users}
              site={selectedSite}
              show={showAddUser}
              toggler={setShowAddUser}
            />
          ) : null}
          {updateUser ? (
            <UpdateUserDialog
              setUser={setUpdateUser}
              user={updateUser}
              firebase={props.firebase}
              organizations={organizations}
              sites={sites}
              show={showUpdateUser}
              toggler={setShowUpdateUser}
            />
          ) : null}
          <UpdateSiteDialog
            site={selectedSite}
            show={showUpdateSite}
            toggler={setShowUpdateSite}
            firebase={props.firebase}
            reload={reloadSite}
          />
          {/*<GeneratePdfDialog site={selectedSiteForPdf} show={showGeneratePdfDialog} toggler={setShowGeneratePdfDialog}/>*/}
          <div className={classes.table}>
            <SitesTable columns={columns} data={sites} users={users} />
          </div>
          {error ? (
            <Typography variant="subtitle2" color="error">
              {error}
            </Typography>
          ) : null}

          {userToRemove ? (
            <Dialog open={showRemoveUser} onClose={() => onClose(false)}>
              <DialogContent>
                {t('remove user from site', {
                  user: userToRemove ? userToRemove.email || '' : '',
                  clientName: selectedSite.clientName,
                  site: selectedSite.name,
                })}
              </DialogContent>
              <DialogActions>
                <Button variant="contained" color="secondary" onClick={() => onClose(false)} disabled={loading}>
                  {t('no')}
                </Button>
                <Button variant="contained" color="primary" onClick={() => removeUser()} disabled={loading}>
                  {t('yes')}
                </Button>
              </DialogActions>
            </Dialog>
          ) : null}
        </div>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <CircularProgress />
        </div>
      )}
    </Container>
  );
};

export default withFirebase(SitesPage);
