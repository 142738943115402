import React, { useEffect, useState } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

import { withFirebase } from '../Firebase';
import { getUserData, isAuthUserAdmin, timeOutInMilliSeconds } from '../../utils/common';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from '../../store/actions/userAction';
import { showToast } from '../../store/actions/toastAction';

const withAuthentication = Component => {
  const WithAuthentication = props => {
    const [loaded, setLoaded] = useState(false);
    const isUserSigning = useSelector(state => state.isUserSigning);
    const dispatch = useDispatch();

    useEffect(
      () =>
        props.firebase.auth.onAuthStateChanged(async authUser => {
          try {
            if (isUserSigning) {
              return;
            }
            if (authUser) {
              const userData = await getUserData(props.firebase, authUser.uid);
              const res = await authUser.getIdTokenResult();
              if (
                new Date().valueOf() - res.claims.auth_time * 1000 >
                timeOutInMilliSeconds(isAuthUserAdmin(userData))
              ) {
                dispatch(updateUser(null));
                props.firebase.doSignOut();
                return;
              }
              dispatch(updateUser(userData));
            } else {
              dispatch(updateUser(null));
            }
            setLoaded(true);
          } catch (e) {
            dispatch(showToast(e, 'error'));
            dispatch(updateUser(null));
            setLoaded(true);
          }
        }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [props.firebase],
    );

    return loaded ? (
      <Component {...props} />
    ) : (
      <div style={{ textAlign: 'center', marginTop: 100 }}>
        <CircularProgress align="center" />
      </div>
    );
  };

  return withFirebase(WithAuthentication);
};

export default withAuthentication;
